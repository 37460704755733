import React, { useEffect } from 'react';
import { useState } from 'react';

import axios from 'axios';

const serverURL = process.env.REACT_APP_serverURL;

const Comments = ({ operationComments, operationId, postingBy }) => {

  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);

  const handleAddComment = async () => {
    if (newComment.trim() === '') {
      // Do not add empty comments
      return;
    }
  
    const newComments = [
      ...comments,
      {
      postedBy: postingBy, 
      content: newComment,
      }
  ];

    try {
      const response = await axios.put(
        `${serverURL}/api/agent/operation/edit-operation/${operationId}/comments`,
        { newComments: newComments }
      );
      // Update the operation state with the new comments
      setComments(response.data);
      // Clear the comment input
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }

  // Clear the comment input
  setNewComment('');
};

const handleDeleteComment = async (index, userConfirm) => {
  if(userConfirm === 'User'){
  const confirmMessage = window.confirm('¿Estás seguro de que quieres borrar este comentario?');
    if (confirmMessage) {
      try {
        const newComments = comments.filter((comment, i) => i !== index);

        const response = await axios.put(
          `${serverURL}/api/agent/operation/edit-operation/${operationId}/comments`,
          { newComments }
        );
        // Update the operation state with the updated comments
        console.log(response.data.comments)
        setComments(response.data);
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  }else{
    alert('No puedes borrar comentarios de agentes');
  }
};

  useEffect(() => {
    setComments(operationComments);
  }, [operationComments]);


  return (
    <div>
      <h3>Comentarios</h3>
      <div className='comments-op'>
        <div>
          <div>
          {comments?.map((comment, index) => (
            <div className='comments-op-card' key={index}>
              <div>
                <b>{comment.postedBy}</b>
                {comment.postedBy === 'User' &&
                <button button='type' onClick={(e)=>handleDeleteComment(index, comment.postedBy)}>Delete</button>
                }
              </div>
              <div>
                <p>{comment.content}</p>
                <i>{comment.createdAt}</i>
              </div>
            </div>
          ))}
          </div>
        </div>  
        <div className='comments-input'>
            <input
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Escribe un mensaje"
            ></input>
            <button type='button' onClick={handleAddComment}>Enviar</button>
        </div>
     </div>
    </div>
    );
  };

export default Comments;
