import React, { useState } from 'react';
import axios from 'axios';

function Shipments() {

  return (
    <div className="main">
      <h1>Envíos</h1>
      <p><i>Realiza tu primera operación para ver información sobre los envíos.</i></p>
    </div>
  );
}

export default Shipments;
