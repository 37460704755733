import React from "react";
import "./Loading.css"; // Estilos para la pantalla de carga

const LoadingScreen = () => {

  return (
    <div className="loading-screen">
      <div className="loader"></div>
      <i style={{ marginTop: "2vw" }}>
        <h3>{"Lo mejor esta por venir"}</h3>
      </i>
    </div>
  );
};

export default LoadingScreen;
