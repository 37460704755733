import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';

import countriesData from '../../../resources/countries.json';

const serverURL = process.env.REACT_APP_serverURL;

const EditOperation = () => {
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [showAddProductSelect, setShowAddProductSelect] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductsArray, setSelectedProductsArray] = useState([]);
  const [isOn, setIsOn] = useState(false);
  const [newProduct, setNewProduct] = useState({
    productName: '',
    tariffClassification: '',
    dimensionsAndWeight: {
      weight: '',
      height: '',
      width: '',
      depth: '' ,
      volume: '',
      volumetricWeight: '',
   },
   quantity: 1,
  });
  const [operation, setOperation] = useState({
        userId: '',
        folio: '',
        createdAt: '',
        importExport: '',
        incoterm: '',
        transactions: {
          goodsValue: '',
          totalTransport: '',
          totalTaxes: '',
          taxes: [],
          otherFees: [],
        },
        products: [],
        transport: {
          origin: {
            cityOrigin: '',
            portOrigin: '',
            countryOrigin: '',
            adress: {
              originZipCode: '',
              originAdressStreet: '',
              originAdressNumber: '',
              originReference: '',
            },
          },
          destination: {
            cityDestination: '',
            portDestination: '',
            countryDestination: '',
            adress: {
              destinationZipCode: '',
              destinationAdressStreet: '',
              destinationAdressNumber: '',
              destinationReference: '',
            },
          },
        },
        comments: [],
        status: '',
      }
  );
  const [newComment, setNewComment] = useState('');

  const params = useParams();
  const navigate = useNavigate();

  const {operationId} = params;
  

  const fetchOperationData = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/api/agent/operation/info-operation/${operationId}`
      );
      fetchProducts(response.data.userId);
      setOperation(response.data);

    } catch (error) {
      console.error('Error fetching operation data:', error);
    }
  }

  const fetchProducts = async (userId) => {
    try {
      const res = await axios.get(`${serverURL}/api/agent/operation/user-products/${userId}`);
      setProducts(res.data);
    } catch (error) {
      console.error(error);
    }

  }

  const handleToggleAddProductForm = () => {
    setShowAddProductSelect(false);
    setShowAddProductForm(true);
  };

  const handleToggleAddProductSelect = () => {
    setShowAddProductForm(false);
    setShowAddProductSelect(true);
  };

  const handleNewProductChange = (e, section, subSection, subSubSection) => {
  
    const { name, value } = e.target;
    const updatedNewProduct = { ...newProduct };
     if (subSubSection) {
      updatedNewProduct[section][subSection][subSubSection][name] = value; 
    }else if (subSection) {
      updatedNewProduct[section][subSection][name] = value;
    } else if (section) {
      updatedNewProduct[section][name] = value;
    } else {
      updatedNewProduct[name] = value;
    }
  
    setNewProduct(updatedNewProduct);

  };
  
  const handleAddNewProduct = () => {
    setOperation({
      ...operation,
      products: [...operation.products, newProduct ]
    });
    setNewProduct({
      productName: '',
      tariffClassification: '',
      dimensionsAndWeight: {
         weight: 0,
         height: 0,
         width: 0,
         depth: 0 ,
         volume: 0,
         volumetricWeight: 0,
      },
      quantity: 1,
    });
    setShowAddProductForm(false);
  };
  
  const handleInputChange = (e, section, subSection, subSubSection) => {
    const { name, value } = e.target;
    const updatedFormData = { ...operation };
     if (subSubSection) {
      updatedFormData[section][subSection][subSubSection][name] = value; 
    }else if (subSection) {
      updatedFormData[section][subSection][name] = value;
    } else if (section) {
      updatedFormData[section][name] = value;
    } else {
      updatedFormData[name] = value;
    }
  
    setOperation(updatedFormData);
  };

  const handleTransactionChange = (e) => {

    const { name, value } = e.target;
    setOperation({
      ...operation,
      transactions: {
        ...operation.transactions,
        [name]: value,
      },
    });
  };
  
  const handleTaxChange = (index, field, value) => {
    const updatedTaxes = [...operation.transactions.taxes];
    updatedTaxes[index][field] = value;
    setOperation({
      ...operation,
      transactions: {
        ...operation.transactions,
        taxes: updatedTaxes,
      },
    });
  };  

  const handleProductChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  /*const handleProductChange = (index, field, value) => {
    const updatedProducts = [...operation.products];
    updatedProducts[index][field] = value;
    setOperation({
      ...operation,
      products: updatedProducts,
    });
  };*/

  const handleAddProduct = () => {
    if (selectedProduct) {
      // Check if the product is already in the operation.products array
      const existingProduct = operation.products.find(
        (product) => product.productId === selectedProduct.productId
      );

      if (existingProduct) {
        // Increment the quantity of the existing product
        const updatedProducts = operation.products.map((product) =>
          product.productId === existingProduct.productId
            ? { ...product, quantity: product.quantity + 1 }
            : product
        );
        setOperation({ ...operation, products: updatedProducts });
      } else {
        // Add the new product to the products array with quantity 1
        setOperation({
          ...operation,
          products: [
            ...operation.products,
            {
              ...selectedProduct,
              quantity: 1,
            },
          ],
        });
      }
      setShowAddProductSelect(false);
      setSelectedProduct(null);
    }
  };

  const handleDeleteProduct = (productId) => {
    setOperation(
      {...operation,
        products: operation.products.filter(product => product._id !== productId)
      });
  }

  const handleQuantityChange = (productId, newQuantity) => {
 
    const newArrayProducts = operation.products.map(product =>
      product.productId === productId ? { ...product, quantity: newQuantity } : product);

    setOperation({...operation,products:newArrayProducts});
  };

  const handleSaveOperation = async () => { 
    const confirmMessage = "¿Estas seguro de guardar los cambios?";
    if (window.confirm(confirmMessage)) {
      try {
        const newOperationData = operation;
        const response = await axios.put(
          `${serverURL}/api/agent/operation/edit-operation/${operationId}`,
          { newOperationData }
        );
        fetchOperationData();
        alert('Operacion actualizada');
      } catch (error) {
        console.error('Error saving operation:', error);
      }
    } 
  }

  const changeStatusOperation = async (status) => {
    const confirmMessage = "¿Estas seguro de cambiar el estatus de la operación?";
    if (window.confirm(confirmMessage)) {

        setOperation({
        ...operation,
        status: status});

        const newOperationData = {
          ...operation,
          status: status,
        }

        try {
          
          const response = await axios.put(`${serverURL}/api/agent/operation/edit-operation/${operationId}`,
            { newOperationData }
            );
            fetchOperationData();
            alert('Operacion publicada al cliente');
        } catch (error) {
          console.error('Error saving operation:', error);
        }
    }
  };

  const cancelEdit = () => {  
    const confirmMessage = "¿Estas seguro de borrar los cambios?";
    if (window.confirm(confirmMessage)) {
      setOperation({
        userId: '',
        folio: '',
        createdAt: '',
        importExport: '',
        incoterm: '',
        transactions: {
          goodsValue: '',
          totalTransport: '',
          totalTaxes: '',
          taxes: [],
          otherFees: [],
        },
        products: [],
        transport: {
          origin: {
            cityOrigin: '',
            portOrigin: '',
            countryOrigin: '',
            adress: {
              originZipCode: '',
              originAdressStreet: '',
              originAdressNumber: '',
              originReference: '',
            },
          },
          destination: {
            cityDestination: '',
            portDestination: '',
            countryDestination: '',
            adress: {
              destinationZipCode: '',
              destinationAdressStreet: '',
              destinationAdressNumber: '',
              destinationReference: '',
            },
          },
        },
        comments: [],
        status: '',
        });
      fetchOperationData();
    }
    navigate(`/agent-dashboard/users/info-operation/${operationId}`)

  };

  const toggleButton = () => {
    setIsOn(!isOn);
  
    // Update the operation array based on the value of isOn
    if (isOn) {
      // If isOn is true, add 'Exportación'
      setOperation({...operation, importExport: 'Exportación'});
    } else {
      // If isOn is false, add 'Importación'
      setOperation({...operation, importExport: 'Importación'});
    }
    console.log(isOn);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchOperationData();
  }, []);

  useEffect(() => {}, [operation]);

  return (
    <div className='main'>
      <a 
      style={{textDecoration:'underline'}}
      onClick={goBack}>← Regresar a operación</a>
      <h1>Editar Operacion</h1>
      <div className='edop-details'>
        <p>ID de la operación: {operationId}</p>
        <p>{operation?.folio}</p>
        <p>Creada en: {operation?.createdAt}</p>
        <hr/>
        <h3>Operation Details</h3>
        <div className={`toggle-button ${isOn ? 'on' : ''}`} onClick={toggleButton}>

          <div className="toggle-indicator">
            <div className="toggle-text">{isOn ? 'Importación' : 'Exportación'}</div>
          </div>

          <div className="toggle-label">{'Exportación   Importación'}</div>
        </div>
        <br/>
        <br/>
        <label>
          Incoterm:
          <input
            className='input-global'
            type="text"
            name="incoterm"
            onChange={handleInputChange}
            placeholder={`${operation.incoterm}`}
          />
        </label>    
      </div>
      <br/>
          <div>
            <p>Pais de origen</p>
                  <input
                        className='input-global'
                        name="countryOrigin"
                        onChange={(e) => {
                          handleInputChange(e, 'transport', 'origin');
                        }}
                        placeholder={`${operation?.transport?.origin?.countryOrigin}`} 
                  />
              </div>

              <div>
            <p>Ciudad de Origen</p>
                  <input
                        className='input-global'
                        name="cityOrigin"
                        onChange={(e) => {
                          handleInputChange(e, 'transport', 'origin');
                        }}
                        placeholder={`${operation?.transport?.origin?.cityOrigin}`} 
                  />
              </div>

              <div>
            <p>Pais de Destino</p>
                  <input
                        className='input-global'
                        name="countryDestination"
                        onChange={(e) => {
                          handleInputChange(e, 'transport', 'destination');
                        }}
                        placeholder={`${operation?.transport?.destination?.countryDestination}`} 
                  />
              </div>

              <div>
              <p>Ciudad de Destino</p>
                  <input
                        className='input-global'
                        name="cityDestination"
                        onChange={(e) => {
                          handleInputChange(e, 'transport', 'destination');
                        }}
                        placeholder={`${operation?.transport?.destination?.cityDestination}`} 
                  />
              </div>
              <br/>
              <hr/>
              <div className='edop-transactions'>
                <h3>Transactions</h3>
                <div>
                  <label>
                    Valor de la mercancia:
                    <input
                      className='input-global'
                      type="text"
                      name="goodsValue"
                      onChange={handleTransactionChange}
                      placeholder={operation?.transactions?.goodsValue}
                    />
                  </label>
                </div>
                <br/>
        
      </div>
        <br/>
      <hr/>
      <div className='edop-products'>
      

        <h3>Products</h3>
        <table class="products-new-op" style={{width:'100%'}}>
                            <tr>
                                    <th>Producto</th>
                                    <th>Fracción Arancelaria</th>
                                    <th>Peso</th>
                                    <th>Volumen</th>
                                    <th>Cantidad</th>
                                    <th>Acciones</th>
                                    <hr/>
                            </tr>
      {operation.products.map.length === 0 && <i style={{marginLeft:'2vw'}}>No hay productos agregados a la operación</i>}
                    {operation?.products.map((product, index) => (                    
                                        <tr style={{
                                            borderBottom: '1px solid gray',
                                            borderTop: '1px solid gray',
                                            
                                            }}>
                                                <td>{product?.productName}</td>
                                                <td>{product?.tariffClassification}</td>
                                                <td>{product?.dimensionsAndWeight?.weight}</td>
                                                <td>{product?.dimensionsAndWeight?.volume}</td>
                                                <td><input
                                                    type="number"
                                                    value={product?.quantity}
                                                    onChange={e => handleQuantityChange(product._id, parseInt(e.target.value, 10))}
                                                    />
                                                </td>
                                                <td>
                                                  <button onClick={() => handleDeleteProduct(product._id)}>Delete</button>
                                                </td>
                                                <hr/>
                                                <hr/>
                                        </tr>
        ))}
          </table>

         <button className='btn-white' onClick={handleToggleAddProductSelect}>Agregar Producto Existente</button>
         <button className='btn-white' onClick={handleToggleAddProductForm}>
          Agregar Nuevo Producto
        </button>
        {showAddProductSelect && (
          <div style={{
            boxShadow:'0 0 10px rgba(0,0,0,0.2)',
            borderRadius: '15px',
            margin: '2vw',
            padding: '2vw',
            }}>
            <h5>Buscar producto de cliente</h5>
            <Select
                value={selectedProduct}
                onChange={handleProductChange}
                options={products.map(product => ({ ...product, label: product.productName }))}
                isSearchable
                placeholder="Search for a product..."
            />
            <br/>
            <button className='btn-round' type="button" onClick={handleAddProduct}>Añadir Producto</button>
          </div>
        )}
          {showAddProductForm && (
            
            <div style={{
              boxShadow:'0 0 10px rgba(0,0,0,0.2)',
              borderRadius: '15px',
              margin: '2vw',
              padding: '2vw',
              }}>
              <h3>Nuevo Producto</h3>
              <p>
                Nombre del Producto:
              </p>
                <input
                  className='input-global'
                  type="text"
                  name="productName"
                  value={newProduct.productName}
                  onChange={(e)=>handleNewProductChange(e)}
                  placeholder='Nombre del producto'
              />

              <p>
                Fracción Arancelaria:
              </p>
                <input
                  className='input-global'
                  type="text"
                  name="tariffClassification"
                  value={newProduct.tariffClassification}
                  onChange={(e)=>handleNewProductChange(e)}
                  placeholder='Fracción Arancelaria'
                />

              <p>
                Peso(kg):
              </p>
                <input
                  className='input-global'
                  type="number"
                  name="weight"
                  value={newProduct.dimensionsAndWeight.weight}
                  onChange={(e)=>handleNewProductChange(e, 'dimensionsAndWeight')}
                  placeholder='Peso en kg'
                />
              

              <p>
                Altura(cm):
              </p>
                <input
                  className='input-global'
                  type="number"
                  name="height"
                  value={newProduct.dimensionsAndWeight.height}
                  onChange={(e)=>handleNewProductChange(e, 'dimensionsAndWeight')}
                  placeholder='Altura en cm'
                />
              
              <p>
                Ancho(cm):
              </p>
                <input
                  className='input-global'
                  type="number"
                  name="width"
                  value={newProduct.dimensionsAndWeight.width}
                  onChange={(e)=>handleNewProductChange(e, 'dimensionsAndWeight')}
                  placeholder='Ancho en cm'
                />

              <p>
                Largo(cm):
              </p>
                <input
                  className='input-global'
                  type="number"
                  name="depth"
                  value={newProduct.dimensionsAndWeight.depth}
                  onChange={(e)=>handleNewProductChange(e, 'dimensionsAndWeight')}
                  placeholder='Largo en cm'
                />
              

              <p>
                Cantidad:
              </p>
                <input
                  className='input-global'
                  type="number"
                  name="quantity"
                  value={newProduct.quantity}
                  onChange={(e)=>handleNewProductChange(e)}
                />
              <br/>
              <br/>
              <button className='btn-round' onClick={handleAddNewProduct}>Añadir producto</button>
            </div>
          )}
      </div>
      <br/>
      <br/>
      <br/>
      <button className='btn-round' type='button' onClick={handleSaveOperation}>Guardar Cambios</button>
      <a
      style={{textDecoration:'underline'}}
      type='button' onClick={cancelEdit}>Borrar Cambios</a>
      <br/>
    <br/>
    <br/>
    <br/>
    {operation.status == 'agentDraft' &&
      <button type='button' onClick={()=>changeStatusOperation('pending')}>Mostrar Operacion a Usuario</button>
    }
    {operation.status == 'pending' &&
      <button type='button' onClick={()=>changeStatusOperation('approved')}>Aprobar operación</button>
    }
    {operation.status == 'approved' &&
      <button type='button' onClick={()=>changeStatusOperation('completed')}>Marcar como completada</button>
    }
    </div>
  );
};

export default EditOperation;
