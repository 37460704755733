import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';

import countries from '../../resources/countries.json';
import './EditProduct.css';

const serverURL = process.env.REACT_APP_serverURL;

const EditProduct = () => {

  const [countriesData, setCountriesData] = useState(countries);
  const [product, setProduct] = useState(
    {
      productName: '',
      tariffClassification: '',
      SKU: '',
      dimensionsAndWeight: {
        height: '',
        width: '',
        depth: '',
        weight: '',
      },
      certifications: [],
      originCountry: '',
      imagesLink: [],
      files: [],
      isHazardousCargo: false,
      isColdChain: false,
      typeOfCargo: '',
    }
  );

  const userId = useSelector(state => state.user.user.userId);
  const { productId } = useParams();

  const getProductData = async () => {
    try {
      const res = await axios.get(`${serverURL}/api/product/info-single-product/${productId}?userId=${userId}`);
      console.log(res.data);
      setProduct(res.data);
 
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleInputChange = (event, section) => {
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: !prevProduct[name],
      }));
    } else if(section) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [section]: {
          ...prevProduct[section],
          [name]: value,
        },
      }));
    }else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
    console.log(product);
  };

  const saveProduct = async () => {
    const confirmMessage = "¿Estas seguro de guardar los cambios?";
    if (window.confirm(confirmMessage)) {
      try {
        const res = await axios.put(`${serverURL}/api/product/edit-product/${productId}?userId=${userId}`, product);
        getProductData();
        alert('Producto actualizado con éxito');
      } catch (err) {
        console.error(err.message);
        alert('Error actualizando el producto, intente más tarde');
      }
    }
};

  const cancelEdit = () => {  
    const confirmMessage = "¿Estas seguro de borrar los cambios?";
    if (window.confirm(confirmMessage)) {
      getProductData();
    }
  };

  useEffect(() => {
    getProductData();
  },[]);

  return (
    <div className="EditProduct">
      <a 
      style={{textDecoration:'underline'}}
      href='/dashboard/product-manager'>← Regresar a productos</a>
      <h2>Editar Producto</h2>
        
          <br />

          <div className='single-input'>
            <div className='input'>
              <p>
                Nombre del Producto:
              </p>
                <input
                  type="text"
                  name="productName"
                  value={product.productName}
                  onChange={handleInputChange}
                />
            </div>
          </div>

          <div className='double-input'>
            <div className='input'>
            <p>
              Fracción Arancelaria: </p>
              <input
                type="text"
                name="tariffClassification"
                value={product.tariffClassification}
                onChange={handleInputChange}
              />
            </div>
            <div className='input'>
              <p>
                SKU:
                </p>
                <input
                  type="text"
                  name="SKU"
                  value={product.SKU}
                  onChange={handleInputChange}
                />
              
            </div>
          </div>

          <div className='double-input'>
            <div className='input'>
              <p>
                Alto en centimetros(cm):
              </p> 
                <input
                  type="text"
                  name="height"
                  value={product.dimensionsAndWeight.height}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            </div>

            <div className='input'>
              <p>
                Ancho en centimetros(cm):
              </p>
                <input
                  type="text"
                  name="width"
                  value={product.dimensionsAndWeight.width}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            </div>

          </div>

          <div className='double-input'>
            <div className='input'>
              <p>
                Largo en centimetros(cm):
              </p>
                <input
                  type="text"
                  name="depth"
                  value={product.dimensionsAndWeight.depth}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            </div>


            <div className='input'>
              <p>
                Peso en Kilogramos(kg):
              </p>
                <input
                  type="text"
                  name="weight"
                  value={product.dimensionsAndWeight.weight}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            
            </div>
          </div>
          
              <p>País de origen</p>
              <Select
                        name="originCountry"
                        onChange={(selectedOption) => {
                          const selectedCountry = selectedOption.label;
                          handleInputChange(
                            { target: { name: 'originCountry', value: selectedCountry } }, 
                          );
                        }}
                        options={Object.keys(countriesData).map(country => 
                          ({ label: country }))}
                        isSearchable
                        placeholder={`${product.originCountry}`}
                  />

          <div className='select'>
            <p>
              Tipo de carga:
              </p>
              <select
                className='select'
                name="typeOfCargo"
                value={product.typeOfCargo}
                onChange={handleInputChange}
              >
                <option value="Seca">Seca</option>
                <option value="Granel">Granel</option>
                <option value="Liquida/Gas">Liquida/Gas</option>
                <option value="Exceso de dimensiones">Exceso de dimensiones</option>
              </select>
            </div>
            <br/>              
          <div className='checkbox'>
          <p>
            Carga especial:
          </p>
            <input
              type="checkbox"
              name="isHazardousCargo"
              checked={product.isHazardousCargo}
              onChange={handleInputChange}
            />
          </div>
          
          <br/>
          <div className='checkbox'>
          <p>
            Is Cold Chain:
          </p>
            <input
              type="checkbox"
              name="isColdChain"
              checked={product.isColdChain}
              onChange={handleInputChange}
            />
          </div>

      <button className="btn-round" onClick={saveProduct}>Guardar Cambios</button>
      <a
      style={{textDecoration:'underline'}}
      type='button' onClick={cancelEdit}>Borrar Cambios</a>
    </div>
  );
};

export default EditProduct;
