import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
//dependencias mapa
import 'react-calendar/dist/Calendar.css';
import Mapbox from 'react-map-gl';

//dependencias calendario
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Sidebar from './Sidebar';
import AgentUsers from './Comercial/Customers/AgentUsers';
import NewOperation from './Operational/Operations/NewOperation';
import DocumentsPerOperation from './Operational/Operations/DocumentsPerOperation';
import EditOperation from './Operational/Operations/EditOperation';
import InfoOperation from './Operational/Operations/InfoOperation';
import Digiter from './Operational/Digiter';
import Classify from './Classification/Classifier/Classify';
import Help from './Help';

import "./DashboardAgent.css"
import active_clients from '../images/icons-mvp/active_clients.svg'
import doc_agent from '../images/icons-mvp/doc_agent.svg'
import approved_op from '../images/icons-mvp/approved_op.svg'
import completed_op from '../images/icons-mvp/completed_op.svg'



const serverURL = process.env.REACT_APP_serverURL;

function AgentDashboard() {
  return (
    <div className="agent-dashboard">
      <Sidebar />
      <Routes>
        <Route path="/" element={<AgentDashboardPrev/>} />
        <Route path="/users" element={<AgentUsers/>} />
        <Route path="/:userId/edit-operation/:operationId" element={<EditOperation/>} />
        <Route path="/:userId/info-operation/:operationId" element={<InfoOperation/>} />
        <Route path="/:userId/operation/:operationId/documents" element={<DocumentsPerOperation/>} /> 
        <Route path="/:userId/new-operation" element={<NewOperation/>} /> 
        <Route path="/digiter" element={<Digiter/>} /> 
        <Route path="/classify" element={<Classify/>} />
        <Route path="/help" element={<Help/>} />
      </Routes>
    </div> 
  );
}

function AgentDashboardPrev() {
  const agentEmail = useSelector(state => state.agent.agent.email);
  return (
    <div className="main agentDash" >
      
      <h1>¡Hola!</h1>
        <div className="container">
          <div>
            <h2>No pierdas el ritmo</h2>
          </div>
          <div className="data">
            <div className="card" id="usersCard">
              <div className="circle" id="orange">
                <img src={active_clients} alt="Users vector" />
              </div>
              <h4>Usuarios<br />agregados</h4>
              <h3>0</h3>

            </div>

            <div className="card" id="operationsCard">
              <div className="circle" id="green">
                <img src={doc_agent} alt="" />
              </div>
              <h4>Operaciones<br />creadas</h4>
              <h3>0</h3>

            </div>

            <div className="card" id="approvedCard">
              <div className="circle" id="pink">
                <img src={approved_op} alt="" />
              </div>
              <h4>Operaciones<br />aprobadas</h4>
              <h3>0</h3>

            </div>

            <div className="card" id="completedCard">
              <div className="circle" id="blue">
                <img src={completed_op} alt="" />
              </div>
              <h4>Operaciones<br />completadas</h4>
              <h3>0</h3>

            </div>
          </div>
        </div>

    </div>
  );
}


export default AgentDashboard;