import React from "react";
import { Link } from "react-router-dom";

import './CommonElements.css';


const CommonElements = () => {
  return (
    <div>
        <br/>
        <br/>
      <Link style={{textDecoration:'none'}} to={'/'}>
        <button className="btn-square">Btn Square</button>
        </Link>
        <br/>
        <br/>
        <Link style={{textDecoration:'none'}} to={'/'}>
        <button className="btn-round">Btn Round</button>
        </Link>
    </div>
  );
}

export default CommonElements;