import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './OperationList.css';

const serverURL = process.env.REACT_APP_serverURL;


const UserDisplay = ({ selectedUser }) => {
    const [selectedOption, setSelectedOption] = useState(
      localStorage.getItem('selectedOption') || 'Borradores'
    );
    const [userOperations, setUserOperations] = useState([]);
    const [displayedOperations, setDisplayedOperations] = useState([]);
    const [selectedOperationType, setSelectedOperationType] = useState('Todo');
    const [error, setError] = useState(null);

    const setSelectedOptionAndSave = (option) => {
      setSelectedOption(option);
      localStorage.setItem('selectedOption', option);
    };

    const userId = useSelector(state => state.user.user.userId);
  
    const fetchUserOperations = async () => {
      try {
        const response = await axios.get(`${serverURL}/api/operation/user-operations/${userId}`);
        const operations = response.data.filteredOperations;
        setUserOperations(operations);
        if (selectedOption === 'Operaciones aprobadas') {
          setDisplayedOperations(operations.approved);
        } else if (selectedOption === 'Operaciones pendientes') {
          setDisplayedOperations(operations.pending);
        } else if (selectedOption === 'Operaciones completadas') {
          setDisplayedOperations(operations.completed);
        } else if (selectedOption === 'Borradores') {
          setDisplayedOperations(operations.userDraft);
        }
        return; // Return the fetched operations
      } catch (error) {
        console.error('Error fetching user operations:', error);
      }
    };
  
    useEffect(() => {
      fetchUserOperations();
    }, [selectedUser]);
  
    useEffect(() => {
      // Update displayedOperations when selectedOption changes
      if (selectedOption === 'Operaciones aprobadas') {
        setDisplayedOperations(userOperations.approved);
      } else if (selectedOption === 'Operaciones pendientes') {
        setDisplayedOperations(userOperations.pending);
      } else if (selectedOption === 'Operaciones completadas') {
        setDisplayedOperations(userOperations.completed);
      }else if (selectedOption === 'Borradores') {
        setDisplayedOperations(userOperations.userDraft);
      }
      
    }, [selectedOption]);
  
    return (
      <div className='main userOperations'>
        <button className='btn-square add-button'
          style={{
            margin:'2vw', 
            marginRight:'2vw',
            marginLeft:'auto'
          }}
        >
          <a 
          style={{textDecoration:'none', color:'white'}}
          href={`/dashboard/new-operation`}>
          + Agregar <br />nueva operación
          </a>
        </button>
      <div className='info-clients-docs-container'>
            <div className='ag-options-operation type-of-operation-duetime'>
                <p
                className={`info-clients-option-documents ${selectedOption === 'Borradores' ? 'selected' : ''}`}
                onClick={() => setSelectedOptionAndSave('Borradores')}
                >
                Borradores ({userOperations?.userDraft?.length})
                </p>
                <p
                className={`info-clients-option-documents ${selectedOption === 'Operaciones pendientes' ? 'selected' : ''}`}
                onClick={() => setSelectedOptionAndSave('Operaciones pendientes')}
                >
                Operaciones pendientes ({userOperations?.pending?.length})
                </p>
                <p
                className={`info-clients-option-documents  ${selectedOption === 'Operaciones aprobadas' ? 'selected' : ''}`}
                value='Operaciones aprobadas'
                onClick={() => setSelectedOptionAndSave('Operaciones aprobadas')}
                >
                Operaciones aprobadas ({userOperations?.approved?.length})
                </p>
                <p
                className={`info-clients-option-documents ${selectedOption === 'Operaciones completadas' ? 'selected' : ''}`}
                onClick={() => setSelectedOptionAndSave('Operaciones completadas')}
                >
                Operaciones completadas ({userOperations?.completed?.length})
                </p>
                <p 
                className='option-filter'
                >

                </p>
                
            </div>

            {/*
            <div className='filter-by-operation-type'>
                <button onClick={() => setSelectedOperationType('Todo')}>Todo</button>
                <button onClick={() => setSelectedOperationType('Importacion')}>Importacion</button>
                <button onClick={() => setSelectedOperationType('Exportacion')}>Exportacion</button>
        </div>*/}

            {displayedOperations?.length > 0 ? displayedOperations?.map((operation) => (
             <div className='operation-card'>
              <Link to={`/dashboard/info-operation/${operation._id}`}>
                  <small><b>{operation.importExport}</b>
                    </small>
                <h4>{operation.products[0]?.productName} - {operation.products[0]?.tariffClassification}</h4>
                <p>
                  {`Desde ${operation.transport.origin.cityOrigin} en 
                  ${operation.transport.origin.countryOrigin} a ${operation.transport.destination.countryDestination}
                  en ${operation.transport.destination.cityDestination}`}
                </p>
                <p>{operation.incoterm}</p>
              </Link>
            </div>
            ))
              : <p><i>{`${selectedOption}`} no existentes para este usuario</i></p>
          }
        
        </div>
      </div>
      
    );
  }

  export default UserDisplay;