import React, { useEffect, useRef, useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { useDispatch, useSelector } from "react-redux";

import "./Classify.css";
import LoadingScreen from "../../../Loading.js";
import { logOutAgent } from "../../../../redux/reducers.js";
//import { useNavigate } from 'react-router-dom';

const Classify = () => {
  const [prompt1, setPrompt1] = useState("");
  const [prompt2, setPrompt2] = useState("");
  const [prompt3, setPrompt3] = useState("");
  const [prompt4, setPrompt4] = useState("");
  const [rightTariff, setRightTariff] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [copyAlert, setCopyAlert] = useState(false);

  //const navigate = useNavigate();
  const pyApiUrl = process.env.REACT_APP_pyApiUrl;
  const tokenAgent = useSelector((state) => state.agent?.agent?.tempToken); 
  

  const handlePromptChange1 = (e) => {
    setPrompt1(e.target.value);
  };

  const handlePromptChange2 = (e) => {
    setPrompt2(e.target.value);
  };

  const handlePromptChange3 = (e) => {
    setPrompt3(e.target.value);
  };

  const handlePromptChange4 = (e) => {
    setPrompt4(e.target.value);
  };

  const handleChange5 = (e) => {
    setRightTariff(e.target.value);
  };

  const handleSendFeedback = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 400);
  };

  const handlePostToApi = async (url, body) => {
    try {
      console.log(url)
      const response = await axios.post(url, body, {
        headers: {
          Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YTc2ZTE2NWYzMTBmNTFkMzZkNzFjMCIsImlhdCI6MTcwNTQ3MTUxMH0.5FahZwBG-PnzZqPguOwV6bh5US0yHhkoAy1kaEUTaRQ",
        },
      });
      
      if (typeof response.data === "string") {
        setResponse(JSON.parse(response.data));
      } else {
        setResponse(response.data);
      }
      setLoading(false)
      
    } catch (error) {
      if (error.response.data.error) {
        
        console.error("Error:", error.response.data.error);
        alert(error.response.data.error);
      } else {
     
        alert("Error al procesar");
      }
      setLoading(false)
    }
  };

  const handleFirstButtonClick = async () => {
    setLoading(true);
    let body = {};

    if (prompt1) {
      body.userDescription = `${prompt1}`;
    }
    if (prompt2) {
      body.userDescription += `\nMade of: ${prompt2}`;
    }
    if (prompt3) {
      body.userDescription += `\nThe intended use is: ${prompt3}`;
    }
    if (prompt4) {
      body.userDescription += `\nIs usually used by: ${prompt4}`;
    }

    const apiUrl = `${pyApiUrl}/api/v2/text-image`;
    try{
      await handlePostToApi(apiUrl, body);
    }catch(err){
      alert(err)
      setLoading(false)
    }
    
  };

  const handleCopyCode = (e) => {
    console.log(e);

    try {
      navigator.clipboard.writeText(e);
      // console.log("texto copiado", e);
      setAlertMessage("¡Texto copiado en el portapapeles!");
      setCopyAlert(true);
      setTimeout(() => {
        setAlertMessage("");
      }, 1500);
    } catch (err) {
      setAlertMessage("Error al copiar el código");
      console.error("Error al copiar el código:", err);
      setCopyAlert(false);
      setTimeout(() => {
        setAlertMessage("");
      }, 1500);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {

  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="TariffPro position-relative">
          <div className="main-content">
            <div className="classifier-section">
              <div className="upload-title">
                <h1>Clasifica un producto en base a texto</h1>
              </div>
              <div className="description-instructions">
                <h2>1. Describe el producto</h2>
                <input
                  type="text"
                  name="url_field"
                  onChange={handlePromptChange1}
                  disabled={loading}
                  placeholder="Descripcion del producto"
                />
              </div>

              <div className="description-instructions">
                <h2>2. Material</h2>
                <input
                  type="text"
                  name="url_field"
                  onChange={handlePromptChange2}
                  disabled={loading}
                  placeholder="De que esta hecho"
                />
              </div>

              <div className="description-instructions">
                <h2>3. Para que sirve</h2>
                <input
                  type="text"
                  name="url_field"
                  onChange={handlePromptChange3}
                  disabled={loading}
                  placeholder="Funcionalidad"
                />
              </div>

              <div className="description-instructions">
                <h2>4. Quien lo usa</h2>
                <input
                  type="text"
                  name="url_field"
                  onChange={handlePromptChange4}
                  disabled={loading}
                  placeholder="Para quien es el producto"
                />
              </div>

              <div className="button-section">
                <button
                  className="btn-classify"
                  onClick={handleFirstButtonClick}
                  disabled={loading}
                >
                  Clasificar
                </button>
              </div>

              {response && <><div className="description-instructions">
                <h2> {"input_right_classification"}</h2>
                <input
                  type="text"
                  name="url_field"
                  onChange={handleChange5}
                  disabled={loading}
                  placeholder="input_right_placeholder"
                />
                <button
                  className="btn-classify"
                  onClick={handleSendFeedback}
                  disabled={loading}
                >
                  Enviar comentarios
                </button>
              </div>
              
              </>
              }

              {response && <h2 id="result">⬇️ {"results"}</h2>}
              
              <br />
              {loading && <p>{"loading_p"}</p>}
              <br />
              <ul>
                {response?.map((item, index) => (
                  <li className="border fs-5 text" key={index}>
                    <strong>Fracción arancelaria:</strong>{" "}
                    <span>{item.fraction.code}</span>
                    {/* Copiar código */}
                    <i
                      class="bi bi-copy ms-3 cursor-pointer"
                      onClick={() => handleCopyCode(item.fraction.code)}
                    ></i>
                    <br />
                    <strong>Nombre:</strong> {item.fraction.name}
                    <br />
                    <br />
                    <section className="d-flex flex-row column-gap">
                      <strong className="fw-semibold text-gray fs-6 w-50">
                        Sección
                      </strong>
                      <strong className="fw-semibold text-gray fs-6 w-100">
                        Nombre
                      </strong>
                    </section>
                    {/* Etiqueta precision */}
                    <span
                      className={`reliability ${
                        item.overall_confidence >= 0 &&
                        item.overall_confidence <= 9
                          ? "bg-danger"
                          : item.overall_confidence > 9 &&
                            item.overall_confidence <= 49
                          ? "bg-warning-subtle"
                          : item.overall_confidence > 49 &&
                            item.overall_confidence <= 69
                          ? "bg-warning"
                          : item.overall_confidence > 69 &&
                            item.overall_confidence <= 91
                          ? "bg-success"
                          : item.overall_confidence > 91 &&
                            item.overall_confidence <= 100
                          ? "bg-info"
                          : ""
                      }`}
                    >
                      {item.overall_confidence >= 0 &&
                      item.overall_confidence <= 9
                        ? `${item.overall_confidence}% Sin resultado`
                        : item.overall_confidence > 9 &&
                          item.overall_confidence <= 49
                        ? `${item.overall_confidence}% Baja confianza`
                        : item.overall_confidence > 49 &&
                          item.overall_confidence <= 69
                        ? `${item.overall_confidence}% Confianza media`
                        : item.overall_confidence > 69 &&
                          item.overall_confidence <= 91
                        ? `${item.overall_confidence}% Confianza alta`
                        : item.overall_confidence > 91 &&
                          item.overall_confidence <= 100
                        ? `${item.overall_confidence}% Muy alta confianza`
                        : ""}
                    </span>
                    {/* Etiqueta precision */}
                    <hr className="my-1" />
                    {/* Sección partidas */}
                    <section className="d-flex flex-column column-gap">
                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.chapter.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Capítulo
                            </p>
                          </div>
                          {/* <div class="d-flex align-items-center justify-content-center w-50">
                            <p
                              className={`border border-3 rounded-circle d-flex align-items-center my-0 justify-content-center w-rem ${
                                item.chapter.confidence >= 0 &&
                                item.chapter.confidence <= 9
                                  ? "border-danger bg-danger-text"
                                  : item.chapter.confidence > 9 &&
                                    item.chapter.confidence <= 49
                                  ? "border-warning-subtle bg-warning-subtle-text"
                                  : item.chapter.confidence > 49 &&
                                    item.chapter.confidence <= 69
                                  ? "border-warning bg-warning-text"
                                  : item.chapter.confidence > 69 &&
                                    item.chapter.confidence <= 91
                                  ? "border-success bg-success-text"
                                  : item.chapter.confidence > 91 &&
                                    item.chapter.confidence <= 100
                                  ? "border-info bg-info-text"
                                  : ""
                              }`}
                            >
                              {item.chapter.confidence}%
                            </p>
                          </div> */}
                        </span>
                        <span className="w-100 fs-6">{item.chapter.name}</span>
                      </div>
                      <hr className="my-1" />
                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.heading.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Partida
                            </p>
                          </div>
                          {/* <div class="d-flex align-items-center justify-content-center w-50">
                            <p
                              className={`border border-3 rounded-circle d-flex align-items-center my-0 justify-content-center w-rem ${
                                item.heading.confidence >= 0 &&
                                item.heading.confidence <= 9
                                  ? "border-danger bg-danger-text"
                                  : item.heading.confidence > 9 &&
                                    item.heading.confidence <= 49
                                  ? "border-warning-subtle bg-warning-subtle-text"
                                  : item.heading.confidence > 49 &&
                                    item.heading.confidence <= 69
                                  ? "border-warning bg-warning-text"
                                  : item.heading.confidence > 69 &&
                                    item.heading.confidence <= 91
                                  ? "border-success bg-success-text"
                                  : item.heading.confidence > 91 &&
                                    item.heading.confidence <= 100
                                  ? "border-info bg-info-text"
                                  : ""
                              }`}
                            >
                              {item.heading.confidence}%
                            </p>
                          </div> */}
                        </span>
                        <span className="w-100 fs-6">{item.heading.name}</span>
                      </div>
                      <hr className="my-1" />
                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.subheading.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Subpartida
                            </p>
                          </div>
                          {/* <div class="d-flex align-items-center justify-content-center w-50">
                            <p
                              className={`border border-3 rounded-circle d-flex align-items-center my-0 justify-content-center w-rem ${
                                item.subheading.confidence >= 0 &&
                                item.subheading.confidence <= 9
                                  ? "border-danger bg-danger-text"
                                  : item.subheading.confidence > 9 &&
                                    item.subheading.confidence <= 49
                                  ? "border-warning-subtle bg-warning-subtle-text"
                                  : item.subheading.confidence > 49 &&
                                    item.subheading.confidence <= 69
                                  ? "border-warning bg-warning-text"
                                  : item.subheading.confidence > 69 &&
                                    item.subheading.confidence <= 84
                                  ? "border-success bg-success-text"
                                  : item.subheading.confidence > 85 &&
                                    item.subheading.confidence <= 100
                                  ? "border-info bg-info-text"
                                  : ""
                              }`}
                            >
                              {item.subheading.confidence}%
                            </p>
                          </div> */}
                        </span>
                        <span className="w-100 fs-6">
                          {item.subheading.name}
                        </span>
                      </div>
                      <hr className="my-1" />
                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.fraction.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Fracción
                            </p>
                          </div>
                          {/* <div class="d-flex align-items-center justify-content-center w-50">
                            <p
                              className={`border border-3 rounded-circle d-flex align-items-center my-0 justify-content-center w-rem ${
                                item.fraction.confidence >= 0 &&
                                item.fraction.confidence <= 9
                                  ? "border-danger bg-danger-text"
                                  : item.fraction.confidence > 9 &&
                                    item.fraction.confidence <= 49
                                  ? "border-warning-subtle bg-warning-subtle-text"
                                  : item.fraction.confidence > 49 &&
                                    item.fraction.confidence <= 69
                                  ? "border-warning bg-warning-text"
                                  : item.fraction.confidence > 69 &&
                                    item.fraction.confidence <= 91
                                  ? "border-success bg-success-text"
                                  : item.fraction.confidence > 91 &&
                                    item.fraction.confidence <= 100
                                  ? "border-info bg-info-text"
                                  : ""
                              }`}
                            >
                              {item.fraction.confidence}%
                            </p>
                          </div> */}
                        </span>
                        <span className="w-100 fs-6">{item.fraction.name}</span>
                      </div>
                    </section>
                    {/* Secci+on partidas */}
                    <hr className="my-1" />
                    {/* Secci+on likes */}
                    <section className="d-flex flex-row justify-content-start w-100">
                      <span className="m-2 cursor-pointer">
                        <i className="bi bi-hand-thumbs-up"></i>
                      </span>
                      <span className="m-2 cursor-pointer">
                        <i className="bi bi-hand-thumbs-down"></i>
                      </span>
                      {/* Secci+on likes */}
                    </section>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {alertMessage && (
            <div
              class={`position-fixed top-50 start-50 translate-middle alert py-5 px-5 shadow-lg p-3 mb-5 rounded ${
                alert ? "alert-success" : "alert-success"
              }`}
              role="alert"
            >
              {alertMessage}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Classify;
