import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Comments from './Comments';
import DocumentsPerOperation from './DocumentsPerOperation';
import { useSelector } from 'react-redux';
import DocumentsPerOperationC from './DocumentsPerOperationC';

const serverURL = process.env.REACT_APP_serverURL;

const InfoOperationUserC = () => {
  const [operation, setOperation] = useState({
        userId: '',
        folio: '',
        createdAt: '',
        importExport: '',
        incoterm: '',
        transactions: {
          goodsValue: '',
          totalTransport: '',
          totalTaxes: '',
          taxes: [],
          otherFees: [],
        },
        products: [],
        transport: {
          origin: {
            cityOrigin: '',
            portOrigin: '',
            countryOrigin: '',
            adress: {
              originZipCode: '',
              originAdressStreet: '',
              originAdressNumber: '',
              originReference: '',
            },
          },
          destination: {
            cityDestination: '',
            portDestination: '',
            countryDestination: '',
            adress: {
              destinationZipCode: '',
              destinationAdressStreet: '',
              destinationAdressNumber: '',
              destinationReference: '',
            },
          },
        },
        comments: [],
        status: '',
      }
  );
  const [newComment, setNewComment] = useState('');

  const params = useParams();
  const navigate = useNavigate();
  const operationId = params.operationId;

  const userId = useSelector(state => state.user.user.userId);

  const fetchOperationData = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/api/operation/info-operation/${operationId}`
      );
    
      setOperation(response.data);

    } catch (error) {
      console.error('Error fetching operation data:', error);
    }
  }

  const goBack = () => {
    navigate(-1);
  };
 
  useEffect(() => {
    fetchOperationData();
  }, []);

  return (
    <div className='main'>
      <a 
      style={{textDecoration:'underline'}}
      onClick={goBack}>← Regresar a operaciones</a>
      <h1>Información de la operación</h1>
      <div className='edop-details'>
        <div style={{
          display: 'flex',
        }}>
          <b>Tipo de operación:</b>
          <button
          style={{
            marginLeft: 'auto',
            backgroundColor: '#fff',
            border: '1px solid #000',
            fontSize: '18px',
            fontWeight: '700'
          }}
          ><a href={`/dashboard/edit-operation/${operationId}`}
          >Editar Operacion</a></button>
        </div>
        <p>{operation.importExport}</p>
        <b>No. de Pedimento:</b>
        <p>NULL</p>
        <b>Detalles de Producto(s):</b>
        <div className='row'>
        {operation.products.map(product => (
                              <div key={product.productId}>
                                <p>
                                  {product.productName} - {product.tariffClassification} - {product.quantity} piezas/kg
                                </p>
                              </div>
                            ))}
        </div>
        <b>Origen y Destino de la mercancia:</b>
        <p>Desde {operation.transport.origin.cityOrigin}, {operation.transport.origin.countryOrigin} a {operation.transport.destination.cityDestination}, {operation.transport.destination.countryDestination}</p>
          
        <b>Incoterm:</b>
        <p>{operation.incoterm}</p>

        <b>Valor de la mercancia:</b>
        <p>$ {operation.transactions.goodsValue}</p>

        <DocumentsPerOperationC
        operationId={operationId}
        userId={userId}
        />

        <Comments
        operationComments={operation.comments}
        operationId={operationId}
        postingBy={'User'}
        />

      </div>
    </div>
  );
};

export default InfoOperationUserC;
