import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import delete_trash from '../../images/icons-mvp/delete_trashcan.svg'
import { get } from 'firebase/database';

const serverURL = process.env.REACT_APP_serverURL;


const ProductManager = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([])

  const userId = useSelector(state => state.user.user.userId);

  const getProducts = async () => {
    try {
      const res = await axios.get(`${serverURL}/api/product/user-products/${userId}`);
      setProducts(res.data);
      setFilteredProducts(res.data);
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleDeleteProduct = async (productId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este producto?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`${serverURL}/api/product/delete-product/${productId}/${userId}`);
      getProducts();
      alert('Producto eliminado');
    } catch (err) { 
      alert('Error al eliminar el producto');
      console.error(err.message);
    }
  };

  useEffect(() => {
    // Filter products based on search term
    const filtered = products.filter(product =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchTerm]);

  useEffect(() => {
    getProducts();
  },[]);

  return (
    <div className='productList main'>
      <h1>Productos</h1>
      <div style={{display:'flex'}}>
        <input
          style={{height:'50%', width:'300px'}}
          type="text"
          placeholder="Buscar un producto por nombre..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className='btn-square'
          style={{
            marginRight:0,
            marginLeft:'auto',
            marginBottom:'2vw'
          }}
        ><a 
        style={{color:'white'}} 
        href='/dashboard/add-product'
        >Añadir nuevo producto</a>
        </button>
      </div>
                    <table class="products-new-op" style={{width:'100%'}}>
                            <tr>
                                    <th>SKU</th>
                                    <th>Producto</th>
                                    <th>Fracción Arancelaria</th>
                                    <th>Tipo de Carga</th>
                                    <th>Acciones</th>
                                    <hr/>
                            </tr>
                
                            {products.length === 0 && <i style={{marginLeft:'2vw'}}>No hay productos agregados a la operación</i>}
                            {filteredProducts.length === 0 && <i style={{marginLeft:'2vw'}}>No hay coincidencias para esta busqueda</i>}
                                {filteredProducts.map((product, index) => (                    
                                        <tr style={{
                                            borderBottom: '1px solid gray',
                                            borderTop: '1px solid gray',
                                            
                                            }}>
                                                <td><a href={`/dashboard/edit-product/${product._id}`}>{product.SKU}</a></td>
                                                <td><a href={`/dashboard/edit-product/${product._id}`}>{product.productName}</a></td>
                                                <td><a href={`/dashboard/edit-product/${product._id}`}>{product.tariffClassification}</a></td>
                                                <td><a href={`/dashboard/edit-product/${product._id}`}>{product.typeOfCargo}</a></td>
                                                <td>
                                                  <button className='action'
                                                  onClick={() => handleDeleteProduct(product._id)}
                                                  ><img src={delete_trash}/></button>
                                                </td>
                                                <hr/>
                                                <hr/>
                                        </tr>
                                ))}
                          </table>
    </div>
  );
};

export default ProductManager;


