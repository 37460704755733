import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './AddProductForm.css';

import countries from '../../resources/countries.json';

const serverURL = process.env.REACT_APP_serverURL;

const AddProductForm = () => {
  const [product, setProduct] = useState({
    productName: '',
    tariffClassification: '',
    SKU: '',
    dimensionsAndWeight: {
      height: '',
      width: '',
      depth: '',
      weight: '',
    },
    certifications: [],
    originCountry: '',
    imagesLink: [],
    filesLink: [],
    isHazardousCargo: false,
    isColdChain: false,
    typeOfCargo: 'Seca',
  });
  const [countriesData, setCountriesData] = useState(countries);
  const navigate = useNavigate();

  const userId = useSelector(state => state.user.user.userId);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(product);
    try{

      const res = await axios.post(`${serverURL}/api/product/create-product/${userId}`, product);
      
      alert('Producto creado exitosamente');
      navigate('/dashboard/product-manager');
    }catch(err){

      console.log(err);
      alert('Error creando producto');
    }
    // Send product data to the API using fetch or your preferred method

  };

  const handleInputChange = (event, section) => {
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: !prevProduct[name],
      }));
    } else if(section) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [section]: {
          ...prevProduct[section],
          [name]: value,
        },
      }));
    }else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  return (
    <div className='add-product'>
      <a 
      style={{textDecoration:'underline'}}
      href='/dashboard/product-manager'>← Regresar a productos</a>
        <h2>Añadir Producto</h2>
        <form onSubmit={handleSubmit}>
        
          <br />

          <div className='single-input'>
            <div className='input'>
              <p>
                Nombre del Producto:
              </p>
                <input
                  type="text"
                  name="productName"
                  value={product.productName}
                  onChange={handleInputChange}
                />
            </div>
          </div>

          <div className='double-input'>
            <div className='input'>
            <p>
              Fracción Arancelaria: </p>
              <input
                type="text"
                name="tariffClassification"
                value={product.tariffClassification}
                onChange={handleInputChange}
              />
            </div>
            <div className='input'>
              <p>
                SKU:
                </p>
                <input
                  type="text"
                  name="SKU"
                  value={product.SKU}
                  onChange={handleInputChange}
                />
              
            </div>
          </div>

          <div className='double-input'>
            <div className='input'>
              <p>
                Alto en centimetros(cm):
              </p> 
                <input
                  type="number"
                  name="height"
                  value={product.dimensionsAndWeight.height}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            </div>

            <div className='input'>
              <p>
                Ancho en centimetros(cm):
              </p>
                <input
                  type="number"
                  name="width"
                  value={product.dimensionsAndWeight.width}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            </div>

          </div>

          <div className='double-input'>
            <div className='input'>
              <p>
                Largo en centimetros(cm):
              </p>
                <input
                  type="number"
                  name="depth"
                  value={product.dimensionsAndWeight.depth}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            </div>


            <div className='input'>
              <p>
                Peso en Kilogramos(kg):
              </p>
                <input
                  type="number"
                  name="weight"
                  value={product.dimensionsAndWeight.weight}
                  onChange={(e)=>handleInputChange(e, 'dimensionsAndWeight')}
                />
            
            </div>
          </div>
          
              <p>País de origen</p>
              <Select
                        name="originCountry"
                        onChange={(selectedOption) => {
                          const selectedCountry = selectedOption.label;
                          handleInputChange(
                            { target: { name: 'originCountry', value: selectedCountry } }, 
                          );
                        }}
                        options={Object.keys(countriesData).map(country => 
                          ({ label: country }))}
                        isSearchable
                        placeholder="Seleccione"
                  />

          <div className='select'>
            <p>
              Tipo de carga:
              </p>
              <select
                className='select'
                name="typeOfCargo"
                value={product.typeOfCargo}
                onChange={handleInputChange}
              >
                <option value="Seca">Seca</option>
                <option value="Granel">Granel</option>
                <option value="Liquida/Gas">Liquida/Gas</option>
                <option value="Exceso de dimensiones">Exceso de dimensiones</option>
              </select>
            </div>
            <br/>              
          <div className='checkbox'>
          <p>
            Carga especial:
          </p>
            <input
              type="checkbox"
              name="isHazardousCargo"
              checked={product.isHazardousCargo}
              onChange={handleInputChange}
            />
          </div>
          
          <br/>
          <div className='checkbox'>
          <p>
            Carga Fría:
          </p>
            <input
              type="checkbox"
              name="isColdChain"
              checked={product.isColdChain}
              onChange={handleInputChange}
            />
          </div>

          <button className='btn-square' type="submit">Añadir Producto</button>
        </form>
      </div>
  );
};

export default AddProductForm;
