import React, { useEffect, useState } from "react";
import './style/Login.css';
import logo from './images/logo.png'
import logo_camtom_forAgents from './images/camtom-forAgents.svg'
import {
  signInWithEmailAndPassword,
 } from "firebase/auth";
 import {auth} from "../firebase.js";
 import { useDispatch } from 'react-redux';
 import { loginUser, loginAgent } from '../redux/reducers.js'; 
 import { useNavigate, useParams } from "react-router-dom";

 import axios from "axios";

 const serverURL = process.env.REACT_APP_serverURL;

function Login() {
    const [username, setUsername] = useState("");
    const [type, setType] = useState('agente');
    const [isOn, setIsOn] = useState(false);
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const params = useParams();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLoginAgent = async (e) => {
        try {
            //firebase call
            const agentCredential = await signInWithEmailAndPassword(auth, username, password);
            
            /* //api node camtom
            const agentIdResponse = await axios.get(`${serverURL}/api/agent/login`, {
                headers: { UID: agentCredential.user.uid }
            });

            //_id mongo del agente
            const agentId = agentIdResponse.data.agentId; */

            //JWT token - incluye id cifrado
            const tempToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YTc2ZTE2NWYzMTBmNTFkMzZkNzFjMCIsImlhdCI6MTcwNTQ3MTUxMH0.5FahZwBG-PnzZqPguOwV6bh5US0yHhkoAy1kaEUTaRQ";
            
            //object for redux
            const agent = {
              agentId: "6643b5e87e7b9c649604aefb",
              email: "pedro.rios@camtomx.com",
              tempToken: tempToken //importante para node.js
            };
            
            //dispatch redux
            dispatch(loginAgent(agent)); 
            setError("");
            navigate(`/agent-dashboard`);
          } catch (error) {
            console.log(error);
            setError("Cuenta inactiva, contacte a soporte"); 
          }
    }

    const handleLoginCompany = async (e) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, username, password);
           
            const userIdResponse = await axios.get(`${serverURL}/api/login/${userCredential.user.uid}`); 
            const userId = userIdResponse.data._id;
  
            const user = {
              userId: userId,
              email: userCredential.user.email,
            };
        
            dispatch(loginUser(user));
            setError("");
            navigate(`/dashboard`);
          } catch (error) {
            console.log(error);
            setError("Cuenta inactiva, contacte a soporte"); 
          }
    }


    const handleSubmit = async (e) => {
        console.log(type);
        e.preventDefault();
        if(type === "agente"){
            handleLoginAgent();
        }else if(type=== "empresa"){
            handleLoginCompany();
        }else{
            setError("Error inciando sesión");
        }
      };
    
    const toggleButton = () => {
        setIsOn(!isOn);
        setType(isOn ? 'empresa' : 'agente');
    };

    useEffect(() => {
/*         if(params.tipoDeUsuario === "agente"){
            setIsOn(true);
            setType("agente");
        } */
    }, []);

    return (
        <div className="login-wrapper login-background">
            
            <div className="form-signup-wrapper">
                
                <form onSubmit={handleSubmit}>
                    {/* <div className={`toggle-button-login ${isOn ? 'on' : ''}`}
                    style={{justifyContent: 'center'}}    
                    onClick={toggleButton}>
                        <div className="toggle-indicator-login">
                            <div className="toggle-text-login">{!isOn ? 'Empresa' : 'Agente'}</div>
                        </div>
                        <div className="toggle-label-login">{'Empresa                Agente'}</div>
                       
                    </div> */}

                    <a href="/">
                            <img 
                            className="logo-login"
                            src={logo} 
                            alt="Logo" />
                    </a>
                        
                    <label>
                        <p><b>Correo Electrónico</b></p>
                        <input type="text" onChange={e => setUsername(e.target.value)} />
                    </label>
                    <label>
                        <p><b>Contraseña</b></p>
                        <input type="password" onChange={e => setPassword(e.target.value)} />
                    </label>
                    <div>
                        <button type="submit">Iniciar Sesión</button>
                    </div>
                    {error && <div className="error">{error}</div>}
                    <br/>
                    <p className="text-center"><a href="/formsinglepage" style={{color:'#004A7C'}}>¿Aún no tienes cuenta?</a></p>
                </form>
            <div className="signup-wrapper">
                
            </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default Login;