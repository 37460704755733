import React, { useState } from 'react';
import axios from 'axios';

const Digiter = () => {
  const [selectedScenario, setSelectedScenario] = useState('single-file');
  const [files, setFiles] = useState([]);
  const [resultLink, setResultLink] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);  // Nuevo estado

  const scenarios = {
    'single-file': 'https://pyapi.camtomx.com/api/v3/digiter/single-file',
    'multiple-file': 'https://pyapi.camtomx.com/api/v3/digiter/multiple-files',
    'join-pdfs': 'https://pyapi.camtomx.com/api/v3/digiter/join-pdfs',
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleScenarioChange = (event) => {
    setSelectedScenario(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);  // Desactivar el botón al iniciar la solicitud

    const formData = new FormData();
    Array.from(files).forEach(file => formData.append('file', file));

    try {
      const response = await axios.post(scenarios[selectedScenario], formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setResultLink(response.data.url_file);
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setIsSubmitting(false);  // Reactivar el botón al completar la solicitud
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <h2 style={styles.title}>Digiter</h2>
        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="file">Sube tu archivo(s):</label>
          <input type="file" id="file" onChange={handleFileChange} multiple />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="scenario">Selecciona una opción:</label>
          <select id="scenario" value={selectedScenario} onChange={handleScenarioChange}>
            <option value="single-file">Un solo archivo</option>
            <option value="multiple-file">Múltiples archivos</option>
            <option value="join-pdfs">Unir PDFs</option>
          </select>
        </div>
        <button type="submit" style={isSubmitting ? styles.buttonDisabled : styles.button} disabled={isSubmitting}>
          {isSubmitting ? 'Procesando...' : 'Convertir'}
        </button>
      </form>
      {resultLink && (
        <div style={styles.result}>
          <a href={resultLink} target="_blank" rel="noopener noreferrer">Descargar Archivo</a>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#555',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  buttonDisabled: {
    padding: '10px 15px',
    backgroundColor: '#ccc',
    color: '#999',
    border: 'none',
    borderRadius: '4px',
    cursor: 'not-allowed',
  },
  result: {
    marginTop: '20px',
    textAlign: 'center',
  },
};

export default Digiter;
