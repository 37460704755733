import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOutAgent } from '../../redux/reducers.js'; 

import logo from '../images/icons-mvp/logo_icon.svg';
import home_icon from '../images/icons-mvp/home.svg';
import digiter from '../images/iconos/docs.svg';
import classify from '../images/iconos/box.svg'
import clients from '../images/icons-mvp/clients.svg';
import logout from '../images/icons-mvp/logout.svg';
import help_icon from '../images/icons-mvp/help.png';

import './Sidebar.css';

import { useSelector } from 'react-redux';

// Importa la acción que limpia el userId del estado de Redux
// Asegúrate de usar la acción correcta de tu aplicación
// import { logOut } from '../redux/actions';

const Sidebar = () => {
  const agentId = useSelector(state => state.agent.agent.Id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOutAgent());
    navigate('/');
  }

  return (
    <div className="sidebar">
      <div className="logoContainer">
        <Link to={`/agent-dashboard`}>
        <img className="logoSidebar" src={logo} alt="Logo"/>
        </Link>
      </div>
      <nav>
        <ul>
          <li><Link to={`/agent-dashboard`}><img src={home_icon} title='Inicio' alt='Icono de inicio'/></Link></li>
          <li><Link to={`/agent-dashboard/users/`}><img src={clients} title='Administrar Clientes' alt='Icono de administrar clientes'/></Link></li>
          <li><Link to={`/agent-dashboard/digiter/`}><img src={digiter} title='Digiter' alt='Icono de administrar clientes'/></Link></li>
          <li><Link to={`/agent-dashboard/classify/`}><img src={classify} title='Classify' alt='Icono de administrar clientes'/></Link></li>
          <li><Link to={`/agent-dashboard/help`}><img src={help_icon} className='help-icon' title='Ayuda' alt='Icono de ayuda'/></Link></li>
        </ul>
      </nav>
      <div onClick={handleLogout} className="logoutContainer">
        <Link>
        <ul>
        <li><img src={logout} alt='icono de cerrar sesion' title='Cerrar Sesión'/></li>
        </ul>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
