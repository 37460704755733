import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import countries from '../../resources/countries.json';

const serverURL = process.env.REACT_APP_serverURL;

const InfoProduct = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [countriesData, setCountriesData] = useState(countries);
  const [product, setProduct] = useState(
    {
      productName: '',
      tariffClassification: '',
      SKU: '',
      dimensionsAndWeight: {
        height: '',
        width: '',
        depth: '',
        weight: '',
      },
      certifications: [],
      originCountry: '',
      imagesLink: [],
      files: [],
      isHazardousCargo: false,
      isColdChain: false,
      typeOfCargo: '',
    }
  );

  const userId = useSelector(state => state.user.user.userId);
  
  const productId = searchParams.get("productId")

  const getProductData = async () => {
    try {
      const res = await axios.get(`${serverURL}/api/product/info-single-product/${productId}?userId=${userId}`);
      console.log(res.data);
      setProduct(res.data);
 
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getProductData();
  },[]);

  return (
    <div className="product-details">
      <a href='/dashboard/product-manager'>Regresar a productos</a>
      <h1>Editar Producto</h1>
      <div>
    
      <h3>Información General:</h3>
        <label>
            Nombre de Producto:
            <input
              type="text"
              name="productName"
              value={product.productName}
            disabled/>
          </label>
          <br />

          <label>
            Fracción Arancelaria:
            <input
              type="text"
              name="tariffClassification"
              value={product.tariffClassification}
            disabled/>
          </label>
          <br />

          <label>
            SKU:
            <input
              type="text"
              name="SKU"
              value={product.SKU}
            disabled/>
          </label>
          <br/>
          <label>
            País de origen:
            <input
              type="text"
              name="originCountry"
              value={product.originCountry}
            disabled/>
          </label>
          <br />

          <label>
            ¿Es carga peligrosa?
            <input
              type="checkbox"
              name="isHazardousCargo"
              checked={product.isHazardousCargo}
            disabled/>
          </label>
          <br />

          <label>
            ¿Es cadena fría?
            <input
              type="checkbox"
              name="isColdChain"
              checked={product.isColdChain}
            disabled/>
          </label>
          <br />

          <label>
            Tipo de carga:
            <input
              type="text"
              name="typeOfCargo"
              value={product.typeOfCargo}
            disabled/>
          </label>

          <br />
          <br />

          <h3>Dimensiones y peso</h3>
          <label>
            Altura:
            <input
              type="text"
              name="height"
              value={product.dimensionsAndWeight.height}
            disabled/>
          </label>
          <br />

          <label>
            Ancho:
            <input
              type="text"
              name="height"
              value={product.dimensionsAndWeight.height}
            disabled/>
          </label>
          <br />
        

          <label>
            Altura:
            <input
              type="text"
              name="height"
              value={product.dimensionsAndWeight.height}
            disabled/>
          </label>
          <br />

          <label>
            Ancho:
            <input
              type="text"
              name="width"
              value={product.dimensionsAndWeight.width}
            disabled/>
          </label>
          <br />

          <label>
            Profundidad:
            <input
              type="text"
              name="depth"
              value={product.dimensionsAndWeight.depth}
            disabled/>
          </label>
          <br />

          <label>
            Peso:
            <input
              type="text"
              name="weight"
              value={product.dimensionsAndWeight.weight}
            disabled/>
          </label>
          <br />
          <br />

          <h3>Certificaciones:</h3>
        <ul>
          {product.certifications.length === 0  ? <p>No hay certificationes para este producto</p> :
          product.certifications.map((certification, index) => (
            <li key={index}>
              Name: {certification.name} - <a href={certification.fileLink}>Download</a>
            </li>
          ))}
        </ul>
        <h3>Images:</h3>
        <ul>
          {product.imagesLink.length === 0  ? <p>No hay imagenes para este producto</p> :
          product.imagesLink.map((imageLink, index) => (
            <li key={index}>
              <img src={imageLink} alt={`Image ${index}`} />
            </li>
          ))}
        </ul>
        <h3>Files:</h3>
        <ul>
        {product.files.length === 0  ? <p>No hay imagenes para este producto</p> :
          product.files.map((fileLink, index) => (
            <li key={index}>
              <a href={fileLink}>Download File {index + 1}</a>
            </li>
          ))}
        </ul>
      </div>
      <button><a href={`/dashboard/edit-product?productId=${productId}`}>Editar</a></button>
    </div>
  );
};

export default InfoProduct;
