import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const serverURL = process.env.REACT_APP_serverURL;

const DocumentsPerOperation = () => {
  
    const [documents, setDocuments] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [documentIdToUpdate, setDocumentIdToUpdate] = useState(null); 
    const [documentTitle, setDocumentTitle] = useState(null);

    const { operationId } = useParams();

    const fetchDocuments = async () => {   
        try {
            const response = await axios.get(`${serverURL}/api/document/operation-documents/${operationId}`);
            setDocuments(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }
    const updateDocument = async (documentId, file) => {  
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.put(`${serverURL}/api/document/upload-file-user-operation/${documentId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type for file upload
                },
            });
            console.log(response.data); // Log the response from the backend
        } catch (error) {
            console.log(error);
        }
    }

    const openModal = (documentId, documentTitle) => {
        setIsModalOpen(true);
        setDocumentIdToUpdate(documentId);
        setDocumentTitle(documentTitle);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setDocumentIdToUpdate(null)
        setDocumentTitle(null);
    };

    useEffect(() => {  
        fetchDocuments();
    }, []);

    return(

        <div className="parent-container">
             {/*Info general del documento*/}
            <div className=''>
                <h1>Documentos</h1>
            </div>

            {/*Filtros que permiten filtrar entre los diferentes documentos*
            <div className='filters'>
                <select>
                    <option value="all">Todos</option>
                    <option value="legal">Legal</option>
                    <option value="contable">Contable</option>
                    <option value="operativo">Operativo</option>
                </select>
                <select>
                    <option value="status">Status</option>
                    <option value="noentregado ">No entregado</option>
                    <option value="enrevision">En revisión</option>
                </select>
            </div>*/}

            {/*titulos de tabla*/}
            <div className='documents-ofoperation-titles'>
                <p>Nombre del documento </p>
                <p>Tipo </p>
                <p>Estatus </p>
                <p>Fecha de carga </p>
                <p>Acciones </p>
            </div>
            {/*display de documentos que se fetchareon*/}
            <div className='documents-ofoperation'>
                {documents.length === 0 && <i style={{margin:'2vw'}}>Tu agente aun no agrega documentos</i>}
                {documents.map((document, index) => (
                    <div key={index} className='documents-ofoperation-row'>
                        <p>{document.title}</p>
                        <p>{document.category}</p>
                        <p>{document.status}</p>
                        <p>Date</p>
                        <p>
                            <button onClick={() => openModal(document.documentId, document.title)}>Subir</button>
                            <button>Descargar</button>
                        </p>
                    </div>
                ))}

                {isModalOpen && (    
                 <FileUploadModal
                        onClose={() => closeModal()}
                        onUpload={(file) => {
                            if (documentIdToUpdate) {
                                updateDocument(documentIdToUpdate, file);
                                setDocumentIdToUpdate(null);
                            }
                        }}
                        documentTitle={documentTitle}
                    />
                )}
            </div>

        </div>


    )

}


export default DocumentsPerOperation;

const FileUploadModal = ({ onClose, onUpload, documentTitle }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSaveChanges = async () => {
        if (file) {
            // Perform the upload action and call onUpload with the selected file
            await onUpload(file);
        }
        onClose();
    };

    return (
        <div className='fileUploadModal-main'>
            <div className="modal-content">
                <h2>Subir: {documentTitle}</h2>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleSaveChanges}>Guardar documento</button>
                <button onClick={onClose}>Cancelar</button>
            </div>
        </div>
    );
};
