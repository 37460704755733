import React, { useEffect, useState } from 'react';
import { database } from '../firebase.js';
import { ref, push } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

import './style.css';
import './style/FormSinglePageC.css';

import logo from './images/logo_bn.svg';

function FormSinglePageC() {
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    correo: '',
    countryCode: '',
    phone: '',
    empresa: '',
    puesto: '',
    cantidadExportacion: '',
    servicios: [],
  });

  const handleCheckboxChange = (event) => {
    const serviceName = event.target.getAttribute('data-service');
    const isChecked = event.target.checked;

    setFormData((prevFormData) => {
      if (isChecked) {
        return {
          ...prevFormData,
          servicios: [...prevFormData.servicios, serviceName],
        };
      } else {
        return {
          ...prevFormData,
          servicios: prevFormData.servicios.filter((service) => service !== serviceName),
        };
      }
    });
  };

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    // Al seleccionar los checkbox el borde cambia de color
    const allInputs = document.querySelectorAll('input');

    allInputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        const parentElement = e.target.closest('.checkbox-container');
        const parentElements = document.querySelectorAll('.checkbox-container');

        parentElements.forEach((element) => {
          if (element !== parentElement) {
            element.style.border = '';
          }
        });

        if (e.target.checked) {
          parentElement.style.border = '2px solid #004A7C';
        } else {
          parentElement.style.border = '';
        }
      });
    });
  }, []);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Verificamos si todos los campos están llenos
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'Este campo es obligatorio';
      }
      if (formData.correo && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.correo)) {
        newErrors.correo = 'Por favor, introduce un correo electrónico válido';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      // Si hay errores, los establecemos y no enviamos el formulario
      setFormErrors(newErrors);
    } else {
      // Aqui enviamos los datos a la base de datos de Firebase
      push(ref(database, 'form/'), formData)
        .then((res) => {
          // Redireccionamos a la página de éxito
          successParam();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleAbandonarFormulario = () => {
    if (window.confirm('¿Estás seguro de que deseas abandonar el formulario?')) {
      navigate('/');
    }
  };

  const successParam = () => {
    let option;

    if (formData.cantidadExportacion === 'provider') {
      option = 'provider';
    } else {
      option = 'customer';
    }

    navigate(`/success/${option}`);
  };

  return (
    <div class="container-total-form">
      <header style={{ backgroundColor: '#002B48' }} className="header-form d-flex flex-wrap align-items-center justify-content-between py-2 mb-4">
        <a href="/" className="col-4 text-dark text-decoration-none">
          <img id="img-logo" src={logo} alt="Camtom logo" />
        </a>

        <button style={{ marginRight: '5vw' }} className="btn-close" onClick={handleAbandonarFormulario}></button>
      </header>

      <div class="container-form">
        <div>
          <h1 className="h1-contactform">
            Estás a punto de unirte a la revolución logística
          </h1>

          <div className="input-block-doble">
            <div className={`input-block ${formErrors.nombre ? 'has-error' : ''}`}>
              <h5 className="label-contactform">Nombre(s)*</h5>
              <input
                className="input-contactform"
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                placeholder="José"
              />
              {formErrors.nombre && <div className="error-message">{formErrors.nombre}</div>}
            </div>

            <div className={`input-block complete-name ${formErrors.apellidos ? 'has-error' : ''}`}>
              <h5 className="label-contactform">Apellidos*</h5>
              <input
                className="input-contactform"
                type="text"
                name="apellidos"
                value={formData.apellidos}
                onChange={handleInputChange}
                placeholder="Ornelas Guzmán"
              />
              {formErrors.apellidos && <div className="error-message">{formErrors.apellidos}</div>}
            </div>
          </div>

          <div className={`input-block ${formErrors.correo ? 'has-error' : ''}`}>
            <h5 className="label-contactform">Correo electrónico*</h5>
            <input
              className="input-contactform"
              type="email"
              name="correo"
              value={formData.correo}
              onChange={handleInputChange}
              placeholder="pepe@tuempresa.com"
            />
            {formErrors.correo && <div className="error-message">{formErrors.correo}</div>}
          </div>

          <div className="input-block-doble">
            <div className="country-code">
              <h5 className="label-contactform">Código</h5>
              <select
                value={formData.countryCode}
                onChange={handleInputChange}
                name="countryCode"
                className="select-contactform-form-select"
              >
                <option value="" selected>Seleccione</option>
                <option value="brazil">+55</option>
                <option value="canada">+1</option>
                <option value="chile">+56</option>
                <option value="china">+86</option>
                <option value="colombia">+57</option>
                <option value="cuba">+53</option>
                <option value="ecuador">+593</option>
                <option value="el-salvador">+503</option>
                <option value="mexico">+52</option>
              </select>
            </div>

            <div className={`input-block phone-number ${formErrors.phone ? 'has-error' : ''}`}>
              <h5 className="label-contactform">Número telefónico</h5>
              <input
                className="input-contactform"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="5512345678"
              />
              {formErrors.phone && <div className="error-message">{formErrors.phone}</div>}
            </div>
          </div>

          <div className={`input-block ${formErrors.empresa ? 'has-error' : ''}`}>
            <h5 className="label-contactform">Nombre de la empresa*</h5>
            <input
              className="input-contactform"
              type="text"
              name="empresa"
              value={formData.empresa}
              onChange={handleInputChange}
              placeholder="Piñas Ornelas SA de CV"
            />
            {formErrors.empresa && <div className="error-message">{formErrors.empresa}</div>}
          </div>

          <div className={`input-block ${formErrors.puesto ? 'has-error' : ''}`}>
            <h5 className="label-contactform">Cargo en la empresa*</h5>
            <select
              value={formData.puesto}
              onChange={handleInputChange}
              name="puesto"
              className="select-contactform-form-select"
            >
              <option value="" selected>Seleccione</option>
              <option value="CEO/Dueño">CEO/Dueño</option>
              <option value="Director">Director</option>
              <option value="Gerente">Gerente</option>
              <option value="Supervisor">Supervisor</option>
              <option value="Coordinador">Coordinador</option>
              <option value="Especialista">Especialista</option>
              <option value="Analista">Analista</option>
              <option value="Practicante">Practicante</option>
              <option value="Otro">Otro</option>
            </select>
            {formErrors.puesto && <div className="error-message">{formErrors.puesto}</div>}
          </div>

          <div className={`input-block ${formErrors.cantidadExportacion ? 'has-error' : ''}`}>
            <h5 className="label-contactform">Cargas mensuales estimadas*</h5>
            <select
              value={formData.cantidadExportacion}
              onChange={handleInputChange}
              className="select-contactform-form-select"
              name="cantidadExportacion"
            >
              <option value="" selected>Seleccione</option>
              <option value="no-ship">Ninguno, no hago movimientos de carga</option>
              <option value="first-ship">Ninguno, es mi primer movimiento</option>
              <option value="junior">0-4</option>
              <option value="small">5-50</option>
              <option value="medium">51-100</option>
              <option value="big">101-300</option>
              <option value="enterprises">Más de 300</option>
              <option value="provider">Soy proveedor logístico</option>
            </select>
            {formErrors.cantidadExportacion && <div className="error-message">{formErrors.cantidadExportacion}</div>}
          </div>

          <div className="input-block">
            <h5 className="label-contactform">¿Qué servicios necesitas?*</h5>
            <div className="container">
                <div>
                <label>
                  <input
                    type="checkbox"
                    name="servicios"
                    data-service="Transporte de carga"
                    checked={formData.servicios.includes('Transporte de carga')}
                    onChange={handleCheckboxChange}
                  />
                  <span>Transporte de carga</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="servicios"
                    data-service="Seguimiento de mercancía"
                    checked={formData.servicios.includes('Seguimiento de mercancía')}
                    onChange={handleCheckboxChange}
                  />
                  <span>Seguimiento de mercancía</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="servicios"
                    data-service="Cotización de flete"
                    checked={formData.servicios.includes('Cotización de flete')}
                    onChange={handleCheckboxChange}
                  />
                  <span>Cotización de flete</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="servicios"
                    data-service="Cálculo de fracción arancelaria"
                    checked={formData.servicios.includes('Cálculo de fracción arancelaria')}
                    onChange={handleCheckboxChange}
                  />
                  <span>Cálculo de fracción arancelaria</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="servicios"
                    data-service="Plataforma para Agentes Aduanales"
                    checked={formData.servicios.includes('Plataforma para Agentes Aduanales')}
                    onChange={handleCheckboxChange}
                  />
                  <span>Plataforma para Agentes Aduanales</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="servicios"
                    data-service="Gestión de documentos aduaneros"
                    checked={formData.servicios.includes('Gestión de documentos aduaneros')}
                    onChange={handleCheckboxChange}
                  />
                  <span>Gestión de documentos aduaneros</span>
                </label>
              </div>
            </div>
          </div>

          <button className="button-submit" onClick={handleSubmit}>Comenzar con Camtom</button>
          {Object.values(formErrors).filter(Boolean).length > 0 && (
            <div className="alert alert-danger">Por favor, llena todos los campos requeridos</div>
          )}
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  );
}

export default FormSinglePageC;
