import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOutUser } from '../../redux/reducers.js'; 

import logo from '../images/logo_w.svg';
import home_icon from '../images/icons-mvp/home.svg';
import box_icon from '../images/icons-mvp/box.svg';
import calendar_icon from '../images/icons-mvp/calendar.svg';
import moneytransfer_icon from '../images/icons-mvp/moneytransfer.svg';
import paidbill_icon from '../images/icons-mvp/paidbill.svg';
import truck_icon from '../images/icons-mvp/truck.svg';
import logout from '../images/icons-mvp/logout.svg';
import help_icon from '../images/icons-mvp/help.png';
import documents_icon from '../images/icons-mvp/document.svg';
import agent_icon from '../images/icons-mvp/agent_icon.svg';

import './Sidebar.css';

import { useSelector } from 'react-redux';

// Importa la acción que limpia el userId del estado de Redux
// Asegúrate de usar la acción correcta de tu aplicación
// import { logOut } from '../redux/actions';

const Sidebar = () => {
  const userId = useSelector(state => state.user.user.userId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOutUser());
    navigate('/');
  }

  return (
    <div className="sidebar-user">
      <div className="logoContainer-user">
        <img className="logoSidebar-user" src={logo} alt="Logo"/>
      </div>
      <nav>
        <ul>
          <li><img src={home_icon}/><Link to={`/dashboard`}>Dashboard</Link></li>
          <li><img src={box_icon}/><Link to={`/dashboard/product-manager`}>Productos</Link></li>
          <li><img src={paidbill_icon}/><Link to={`/dashboard/reports/${userId}`}>Cotizaciones</Link></li>
          <li><img src={truck_icon}/><Link to={`/dashboard/operations`}>Operaciones</Link></li>
          {/*<li><img src={moneytransfer_icon}/><Link to={`/dashboard/transactions`}>Transacciones</Link></li>*/}
          {/*<li><img src={truck_icon}/><Link to={`/dashboard/shipments`}>Envíos</Link></li>*/}
          <li><img src={agent_icon}/><Link to={`/dashboard/agent-manager`}>Agente aduanal</Link></li>
          <li><img src={help_icon} className='help-icon-user'/><Link to={`/dashboard/help`}>Ayuda</Link></li>
          {/*<li><img src={calendar_icon}/>Calendario<br/>(Proximamente)</li>*/}
        </ul>
      </nav>
      <br/>
      <div className="buttonContainer-user">
        <Link to={`/dashboard/quotation/`}><button className="newQuotationButton-user">Nueva Cotización</button></Link>
      </div>
      <div onClick={handleLogout} className="logoutContainer-user">
        <Link>
        <ul>
        <li><img src={logout}/>Cerrar Sesión</li>
        </ul>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
