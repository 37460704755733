import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

import './styles/NewOperation.css'

import countriesData from '../../../resources/countries.json';

const serverURL = process.env.REACT_APP_serverURL;

const NewOperation = () => {
  const [isOn, setIsOn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductsArray, setSelectedProductsArray] = useState([]);
  const [formData, setFormData] = useState({
      importExport: "Exportación",   
      incoterm: "",
      transactions:{
        goodsValue: "",
      },
      transport: {
        origin:{
          cityOrigin: "",
          portOrigin: "",
          countryOrigin: "",
          adress: {
            originZipCode: "",
            originAdressStreet: "",
            originAdressNumber: "",
            originReference: "",
          },
        },
        
        destination:{
          cityDestination: "",
          portDestination: "",
          countryDestination: "",
          adress:{
            destinationZipCode: "",
            destinationAdressStreet: "",
            destinationAdressNumber: "",
            destinationReference: "",
          },
        },
      },
  });
  const [formErrors, setFormErrors] = useState({
    importExport: "",   
      incoterm: "",
      transactions:{
        goodsValue: "",
      },
      transport: {
        origin:{
          cityOrigin: "",
          portOrigin: "",
          countryOrigin: "",
          adress: {
            originZipCode: "",
            originAdressStreet: "",
            originAdressNumber: "",
            originReference: "",
          },
        },
        
        destination:{
          cityDestination: "",
          portDestination: "",
          countryDestination: "",
          adress:{
            destinationZipCode: "",
            destinationAdressStreet: "",
            destinationAdressNumber: "",
            destinationReference: "",
          },
        },
      },
  });

  const navigate = useNavigate();
  const params = useParams();

  const userId = params.userId;

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`${serverURL}/api/agent/operation/user-products/${userId}`);
      setProducts(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleProductChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  const handleAddProduct = () => {
    if (selectedProduct) {
      // Check if the product is already in the selectedProductsArray
      const existingProduct = selectedProductsArray.find(product => product.productId === selectedProduct.productId);

      if (existingProduct) {
        // Increment the quantity of the existing product
        setSelectedProductsArray(prevArray => prevArray.map(product =>
          product.productId === existingProduct.productId
            ? { ...product, quantity: product.quantity + 1 }
            : product
        ));
      } else {
        // Add the new product to the selectedProductsArray
        setSelectedProductsArray(prevArray => [
          ...prevArray,
          { ...selectedProduct, quantity: 1 }
        ]);
      }

      setSelectedProduct(null);
    }
  };

  const handleDeleteProduct = (productId) => {
    setSelectedProductsArray(prevArray => prevArray.filter(product => product.productId !== productId));
  };

  const handleQuantityChange = (productId, newQuantity) => {
 
    const newArrayProducts = selectedProductsArray.map(product =>
      product.productId === productId ? { ...product, quantity: newQuantity } : product);

    setSelectedProductsArray(newArrayProducts);
  };

  const handleInputChange = (e, section, subSection, subSubSection) => {
    /*funcion onChange={e => handleInputChange(e, 'section', 'subSection')}
      ejemplo = {
        name:{
          section:{
            subSection:{
              name: value
            }
          }
        },
        name:value,
        name:value,
        name:value,
      }
      Notese que si no se especifica section o subSection, 
      se asume que es el primer nivel, o sea name:value. 
      Para editar una propiedad name se utiliza:
      onchange={e => handleInputChange(e)}
    */
    const { name, value } = e.target;
    const updatedFormData = { ...formData };
     if (subSubSection) {
      updatedFormData[section][subSection][subSubSection][name] = value; 
    }else if (subSection) {
      updatedFormData[section][subSection][name] = value;
    } else if (section) {
      updatedFormData[section][name] = value;
    } else {
      updatedFormData[name] = value;
    }
  
    setFormData(updatedFormData);
  };
  

  const toggleButton = () => {
    setIsOn(!isOn);
    const type = isOn ? ('Exportación') : ('Importación');
    setFormData({ ...formData, importExport: type });
  };

  const goBack = () => {
    navigate(-1);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica si todos los campos están llenos

    const newOperation = {
      ...formData,
      userId: userId,
      products: selectedProductsArray,
      status: 'agentDraft'
    };
    
    try {
      const quoteRes = await axios.post(`${serverURL}/api/agent/operation/create-operation/${userId}`, newOperation);
      alert('Operacion creada exitosamente');
      navigate('/agent-dashboard/users');
    } catch (error) {
      console.error(error);
      setErrorMessage('Hubo un problema al enviar el formulario. Por favor, inténtalo de nuevo.');
    }
  
  };

  
  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <div className="new-op">
      <a 
      style={{textDecoration:'underline'}}
      onClick={goBack}>← Regresar a clientes</a>
      <h1>Crear nueva operación</h1>
      <form onSubmit={handleSubmit} className="quote-form">
        <br/>
        <br/>
        <h3>Tipo de operación</h3>
        <div className={`toggle-button ${isOn ? 'on' : ''}`} onClick={toggleButton}>

          <div className="toggle-indicator">
            <div className="toggle-text">{isOn ? 'Importación' : 'Exportación'}</div>
          </div>

          <div className="toggle-label">{'Exportación   Importación'}</div>
        </div>
        <br/>
        <div>
                <div className='new-op-products'>
                  <h3 >Detalles del Producto</h3>
                <div className='double-input'>
                  <div className={`input ${formErrors.incoterm ? 'has-error' : ''}`}>
                    <p className="">Incoterm</p>
                    <input 
                      className=""
                      type="text"
                      name="incoterm"
                      value={formData.incoterm}
                      onChange={(e)=>handleInputChange(e)}
                      placeholder="Seleccione"
                    />
                    {formErrors.incoterm && <div className="error-message">{formErrors.incoterm}</div>}
                  </div>

                  <div className={`input ${formErrors.transactions.goodsValue ? 'has-error' : ''}`}>
                    
                    <p className="">Valor de la mercancía(US Dollar)</p>
                    <input 
                      className=""
                      type="number"
                      name="goodsValue"
                      value={formData.goodsValue}
                      onChange={(e)=>handleInputChange(e, 'transactions')}
                      placeholder="$0.00"
                    />
                    {formErrors.transactions.goodsValue && <div className="error-message">{formErrors.transactions.goodsValue}</div>}
                  </div>
                </div>
                <br/>
                  <div className='new-op-select-product'>
                      <p>Search a Product</p>
                      <Select
                          value={selectedProduct}
                          onChange={handleProductChange}
                          options={products.map(product => ({ ...product, label: product.productName }))}
                          isSearchable
                          placeholder="Buscar producto de cliente..."
                      />
                      <button 
                      style={{marginTop:'1vw', width:'100%'}}
                      className="btn-transparent" 
                      type="button" 
                      onClick={handleAddProduct}>Añadir Producto</button>
                      
                      <div>
                        <br></br>
                        <p>Productos seleccionados:</p>

                          <table class="products-new-op" style={{width:'100%'}}>
                            <tr>
                                    <th>Producto</th>
                                    <th>Fracción Arancelaria</th>
                                    <th>Peso</th>
                                    <th>Volumen</th>
                                    <th>Cantidad</th>
                                    <th>Acciones</th>
                                    <hr/>
                            </tr>
                
                            {selectedProductsArray.length === 0 && <i style={{marginLeft:'2vw'}}>No hay productos agregados a la operación</i>}
                                {selectedProductsArray.map((product, index) => (                    
                                        <tr style={{
                                            borderBottom: '1px solid gray',
                                            borderTop: '1px solid gray',
                                            
                                            }}>
                                                <td>{product.productName}</td>
                                                <td>{product.tariffClassification}</td>
                                                <td>{product.dimensionsAndWeight.weight}</td>
                                                <td>{product.dimensionsAndWeight.volume}</td>
                                                <td><input
                                                    type="number"
                                                    value={product.quantity}
                                                    onChange={e => handleQuantityChange(product.productId, parseInt(e.target.value, 10))}
                                                    />
                                                </td>
                                                <td>
                                                  <button onClick={() => handleDeleteProduct(product.productId)}>Delete</button>
                                                </td>
                                                <hr/>
                                                <hr/>
                                        </tr>
                                ))}
                          </table>
                      </div>
                  </div>

                </div>
                <br/>                
              <h3 className="">Dirección de Origen</h3>

              <div className='single-input'>
                  <p className="">País</p>
      
                  {/*<Select
                        name="countryOrigin"
                        onChange={(selectedOption) => {
                          const selectedCountry = selectedOption.label;
                          handleInputChange(
                            { target: { name: 'countryOrigin', value: selectedCountry } }, 
                            'transport', 'origin'
                          );
                        }}
                        options={Object.keys(countriesData).map(country => 
                          ({ label: country }))}
                        isSearchable
                        placeholder="Search for a country..."
                  />*/}
                  
                    <input
                      className=""
                      type="text"
                      name="countryOrigin"
                      value={formData.transport.origin.countryOrigin}
                      onChange={(e)=>handleInputChange(e, 'transport', 'origin')}
                      placeholder="País"
                    />
              </div>
              
              <div className={`double-input `}>
                  

                <div className='input'>  
                  <p className="">Ciudad</p>         
                  <input
                      className=""
                      type="text"
                      name="cityOrigin"
                      value={formData.transport.origin.cityOrigin}
                      onChange={(e)=>handleInputChange(e, 'transport', 'origin')}
                      placeholder="Ciudad"
                    />
                </div>

                <div className={`input ${formErrors.transport.origin.adress.originZipCode ? 'has-error' : ''}`}>
                    
                    <p className="">Código Postal</p>
                    <input
                      className=""
                      type="text"
                      name="originZipCode"
                      value={formData.transport.origin.adress.originZipCode}
                      onChange={(e)=>handleInputChange(e, 'transport', 'origin', 'adress')}
                      placeholder="00000"
                    />
                    {formErrors.transport.origin.adress.originZipCode && <div className="error-message">{formErrors.transport.origin.adress.originZipCode}</div>}
                </div>
                
              </div>

              <div className='double-input'>                
                <div className={`input ${formErrors.transport.origin.adress.originAdressStreet ? 'has-error' : ''}`}>
                
                  <p className="">Calle Principal</p>
                  <input
                    className=""
                    type="text"
                    name="originAdressStreet"
                    value={formData.transport.origin.adress.originAdressStreet}
                    onChange={(e)=>handleInputChange(e, 'transport', 'origin', 'adress')}
                    placeholder="Calle Principal"
                  />
                  {formErrors.transport.origin.adress.originAdressStreet && <div className="error-message">{formErrors.transport.origin.adress.originAdressStreet}</div>}
                </div>

                <div className={`input ${formErrors.transport.origin.adress.originAdressNumber ? 'has-error' : ''}`}>
                
                  <p className="">Numeración</p>
                  <input
                    className=""
                    type="text"
                    name="originAdressNumber"
                    value={formData.transport.origin.adress.originAdressNumber}
                    onChange={(e)=>handleInputChange(e, 'transport', 'origin', 'adress')}
                    placeholder="1010"
                  />
                  {formErrors.transport.origin.adress.originAdressNumber && <div className="error-message">{formErrors.transport.origin.adress.originAdressNumber}</div>}
                </div>
              </div>

              <div className={`reference ${formErrors.transport.origin.adress.originReference ? 'has-error' : ''}`}>
                
                <p className="">Referencia</p>
                <input
                  className=""
                  type="text"
                  name="originReference"
                  value={formData.transport.origin.adress.originReference}
                  onChange={(e)=>handleInputChange(e, 'transport', 'origin', 'adress')}
                  placeholder="Referencia"
                />
                {formErrors.transport.origin.adress.originReference && <div className="error-message">{formErrors.transport.origin.adress.originReference}</div>}
              </div>
              <br/>             
              <h3 >Dirección de Destino</h3>

              <div className={`single-input`}>
                  <p className="">País</p>
                  <input
                      className=""
                      type="text"
                      name="countryDestination"
                      value={formData.transport.destination.countryDestination}
                      onChange={(e)=>handleInputChange(e, 'transport', 'destination')}
                      placeholder="País"
                    />

              </div>
              
              <div className='double-input'>

                <div className={`input `}>
                    <p className="">Ciudad</p>
                   {/* <Select
                          className='select-interactive'
                          name="cityDestination"
                          onChange={(selectedOption) => {
                            const selectedCity = selectedOption.label;
                            handleInputChange(
                              { target: { name: 'cityDestination', value: selectedCity } }, 
                              'transport', 'destination'
                            );
                          }}
                          options={ formData.transport.destination.countryDestination ?
                            countriesData[formData.transport.destination.countryDestination]
                            .map(city => 
                            ({ label: city })): 
                            <option value="" selected>Seleccione una pais primero</option>}
                          isSearchable
                          placeholder="Search for a city..."
                    />*/}
                    <input
                      className=""
                      type="text"
                      name="cityDestination"
                      value={formData.transport.destination.cityDestination}
                      onChange={(e)=>handleInputChange(e, 'transport', 'destination')}
                      placeholder="País"
                    />
                  
                </div>
                           
                <div className={`input ${formErrors.transport.destination.adress.destinationZipCode ? 'has-error' : ''}`}>
                  
                  <p className="">Código Postal</p>
                  <input
                    className=""
                    type="text"
                    name="destinationZipCode"
                    value={formData.transport.destination.adress.destinationZipCode}
                    onChange={(e)=>handleInputChange(e, 'transport', 'destination', 'adress')}
                    placeholder="00000"
                  />
                  {formErrors.transport.destination.adress.destinationZipCode && <div className="error-message">{formErrors.transport.destination.adress.destinationZipCode}</div>}
                </div>

              </div> 
              <div className='double-input'>                 
                <div className={`input ${formErrors.transport.destination.adress.destinationAdressStreet ? 'has-error' : ''}`}>
                  
                  <p>Calle principal</p>
                  <input
                    className=""
                    type="text"
                    name="destinationAdressStreet"
                    value={formData.transport.destination.adress.destinationAdressStreet}
                    onChange={(e)=>handleInputChange(e, 'transport', 'destination', 'adress')}
                    placeholder="Calle principal"
                  />
                  {formErrors.transport.destination.adress.destinationAdressStreet && <div className="error-message">{formErrors.transport.destination.adress.destinationAdressStreet}</div>}
                </div>

                <div className={`input ${formErrors.transport.destination.adress.destinationAdressNumber ? 'has-error' : ''}`}>
                  <p className="">Numeración</p>
                  <input
                    className=""
                    type="text"
                    name="destinationAdressNumber"
                    value={formData.transport.destination.adress.destinationAdressNumber}
                    onChange={(e)=>handleInputChange(e, 'transport', 'destination', 'adress')}
                    placeholder="1010"
                  />
                  {formErrors.transport.destination.adress.destinationAdressNumber && <div className="error-message">{formErrors.transport.destination.adress.destinationAdressNumber}</div>}
                </div>
              </div>                
              <div className={`reference ${formErrors.transport.destination.adress.destinationReference ? 'has-error' : ''}`}>
              
                <p className="">Referencia</p>
                <input
                  className=""
                  type="text"
                  name="destinationReference"
                  value={formData.transport.destination.adress.destinationReference}
                  onChange={(e)=>handleInputChange(e, 'transport', 'destination', 'adress')}
                  placeholder="Referencia"
                />
                {formErrors.transport.destination.adress.destinationReference && <div className="error-message">{formErrors.transport.destination.adress.destinationReference}</div>}
              </div>
        </div>

              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <button className="btn-round" 
              style={{
                marginTop:'3vw',
                width:'400px',
              }}
              type="submit">Crear nueva operación</button>
              <br/>
              <br/>
              <br/>
      </form>
    </div>
  );  
}

export default NewOperation;
