import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import './Operations.css';

const serverURL = process.env.REACT_APP_serverURL;


const AgentManager = () => {
  
    const [selectedOption, setSelectedOption] = useState('agents');
    const [userAgents, setUserAgents] = useState([]);
    const [invites, setInvites]= useState({});

    const userId = useSelector(state => state.user.user.userId)

    const getInvites = async () => {
        // Simulate sending the invitation to the backend
        try {
    
          const res = await axios.get(`${serverURL}/api/agent/users/get-invites?userId=${userId}`);
          setInvites(res.data);
        } catch (error) {
          console.error('Error inviting user:', error);
          return [];
        }
    };

    const handleInviteAction = async (inviteId, agentId, inviteStatus) => {
        try {
          const response = await axios.put(`${serverURL}/api/agent/users/update-invite`, {
            userId: userId,
            agentId: agentId, // Replace this with the correct agentId
            inviteId: inviteId,
            inviteStatus: inviteStatus,
          });
          getInvites();
          getUserAgents();
        } catch (error) {
          console.error('Error updating invite:', error);
        }
      };

    const getUserAgents = async () => {
        try {
          const response = await axios.get(`${serverURL}/api/agent/users/get-user-agents?userId=${userId}`);
          console.log(response)
          setUserAgents(response.data);
        } catch (error) {
          console.error('Error getting user agents:', error);
        }
    };
    
  
    useEffect(() => {
      //espera a que getInvites termine sus promises
      getUserAgents();
      getInvites();
    }, []);

    return(

        <div className='docs-container'>
            <h1>Agent Manager</h1>
            <p><i>Administra los agentes que tienen acceso a tus operaciones, 
                si deseas que un agente tenga acceso a una operacion, deberas darle acceso
                desde la operacion misma.
                </i></p>
            <div className='type-of-operation-duetime row'>
                <p
                className={`option-documents col-3 ${selectedOption === 'agents' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('agents')}
                >
                Agentes activos
                </p>
                <p
                className={`col-3 option-documents ${selectedOption === 'pending' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('pending')}
                >
                Invitaciones pendientes
                </p>
                {/*<p
                className={`option-documents col-3 ${selectedOption === 'completed' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('completed')}
                >
                Invitaciones completadas
                 </p>*/}
                <p className='option-documents col-6'>
                </p>
                
                
            </div>

            {selectedOption == 'agents' ? 
                <div className='list-agents'>
                {userAgents.length == 0 ? <i>No tienes agentes activos</i> : null}
                    {userAgents.map((agent,index) =>(
                    <div className='card p-3' key={index}>
                        <h4>{agent.name}</h4>
                        <p>Email: {agent.email}</p>
                        <p>Username: {agent.username}</p>
                    </div>
                    ))}
                </div>
            :null
            }
            {selectedOption == 'pending' ? 
                <div>
                    {invites.pending?.length == 0 ? <i>No tienes invitaciones pendientes</i> : null}
                    {invites.pending?.map((invite,index) =>(
                    <div className='card p-3' key={index}>
                        <h4>{invite.agentName}</h4>
                        <p>{invite.createdAt}</p>
                        <button className='btn-square' onClick={() => handleInviteAction(invite._id, invite.agentId, 'approved')}>
                            Aceptar
                        </button>
                        <button className='btn-transparent' onClick={() => handleInviteAction(invite._id, invite.agentId, 'rejected')}>
                            Rechazar
                        </button>
                    </div>
                    ))}
                </div>
            :null
            }
            {/*{selectedOption == 'completed' ? 
                <div >
                    {invites.approved?.map((invite,index) =>(
                    <div key={index}>
                        <h4>{invite.agentName}</h4>
                        <p>{invite.createdAt}</p>
                        <p><i>Aprobada</i></p>
                    </div>
                    ))}
                    {invites.rejected?.map((invite,index) =>(
                    <div key={index}>
                        <h4>{invite.agentName}</h4>
                        <p>{invite.createdAt}</p>
                        <p><i>Rechazada</i></p>
                    </div>
                    ))}
                </div>
            :null
            }*/}
            
        
        </div>


    )

}

export default AgentManager;