import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import delete_trash from '../../images/icons-mvp/delete_trashcan.svg'
import download_icon from '../../images/icons-mvp/download_icon.svg'
import edit_icon from '../../images/icons-mvp/pencil_icon.svg'


const serverURL = process.env.REACT_APP_serverURL;

const DocumentsPerOperationC = () => {
  
    const [documents, setDocuments] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [documentIdToUpdate, setDocumentIdToUpdate] = useState(null); 
    const [documentTitle, setDocumentTitle] = useState(null);

    const { operationId } = useParams();
    const userId = useSelector(state => state.user.user.userId);

    const fetchDocuments = async () => {   
        try {
            const response = await axios.get(`${serverURL}/api/document/operation-documents/${operationId}`);
            setDocuments(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const updateDocument = async (documentId, file, titleDoc) => { 
        if (file.size > 30 * 1024 * 1024) {
            alert('Archivo demasiado grande, excede los 30MB permitidos');
            return; 
        } 
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.put(`${serverURL}/api/document/upload-file-user-operation/${documentId}/${operationId}/${userId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Title': titleDoc
                },
            });
            console.log(response.data);
            fetchDocuments();
            // Log the response from the backend
        } catch (error) {
            console.log(error);
        }
    }

    const downloadDocument = async (documentId) => {  
        try {
            const response = await axios.get(`${serverURL}/api/document/file-url/${documentId}/${userId}`);

            window.open(response.data.url);
            
        } catch (error) {
            alert('Error descargando documento');
            console.log(error);
        }
    }

    
    const deleteDocument = async (documentId) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este documento?');
        if (!confirmDelete) return;
        try {
            const response = await axios.delete(`${serverURL}/api/document/delete-document-from-operation/${documentId}/${operationId}`);
            fetchDocuments();
        } catch (error) {
            console.log(error);
        }
    }

    const openModal = (documentId, documentTitle) => {
        setIsModalOpen(true);
        setDocumentIdToUpdate(documentId);
        setDocumentTitle(documentTitle);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setDocumentIdToUpdate(null)
        setDocumentTitle(null);
    };

    useEffect(() => {  
        fetchDocuments();
    }, []);
    return(

        <div className="parent-container docs-per-op">
             {/*Info general del documento*/}
            <div className=''>
                <h3>Documentos</h3>
            </div>
            {/*Filtros que permiten filtrar entre los diferentes documentos*
            <div className='filters'>
                <select>
                    <option value="all">Todos</option>
                    <option value="legal">Legal</option>
                    <option value="contable">Contable</option>
                    <option value="operativo">Operativo</option>
                </select>
                <select>
                    <option value="status">Status</option>
                    <option value="noentregado ">No entregado</option>
                    <option value="enrevision">En revisión</option>
                </select>
            </div>*/}

            {/*titulos de tabla*/}
            <table class="documents-op" style={{width:'100%'}}>
                <tr>
                        <th>Nombre del documento</th>
                        <th>Tipo</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                        <hr/>
                </tr>
                {/*display de documentos que se fetchareon*/}
    
                {documents.length === 0 && <i style={{marginLeft:'2vw'}}>Espera que tu agente agregue un documento para verlos desplegados</i>}
                    {documents.map((document, index) => (                    
                            <tr style={{
                                borderBottom: '1px solid gray',
                                borderTop: '1px solid gray',
                                
                                }}>
                                    <td>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the default link behavior
                                                openModal(document.documentId, document.title);
                                            }}
                                        >
                                            +{document.title}
                                        </a>
                                    </td>
                                    <td>{document.category}</td>
                                    <td>{document.status}</td>
                                    <td>
                                        <button 
                                            className='action'
                                            onClick={(e) => {
                                                    e.preventDefault(); 
                                                    openModal(document.documentId, document.title);
                                                }} ><img src={edit_icon}/></button>
                                            <button 
                                            className='action'
                                            onClick={()=>downloadDocument(document.documentId)}
                                            ><img src={download_icon}/></button>
                                            <button className='action'
                                            onClick={()=>deleteDocument(document.documentId)}
                                            ><img src={delete_trash}/>
                                        </button>
                                    </td>
                                    <hr/>
                                    <hr/>
                            </tr>
                    ))}
                    
                    {isModalOpen && (    
                    <FileUploadModal
                            onClose={() => closeModal()}
                            onUpload={(file) => {
                                if (documentIdToUpdate) {
                                    updateDocument(documentIdToUpdate, file, documentTitle);
                                    setDocumentIdToUpdate(null);
                                }
                            }}
                            documentTitle={documentTitle}
                        />
                    )}
            </table>
           {/* <a className='addDoc' onClick={()=>{setShowAddDocumentForm(true)}}>+ Agregar documento necesario</a>
                {showAddDocumentForm && (
                    <FileAddModal
                        onClose={() => setShowAddDocumentForm(false)}
                        addNewDocument={addNewDocument}
                        handleInputChange={handleInputChange}
                        newDocumentData={newDocumentData}
                    />
                )} */}
        </div>


    )

}


export default DocumentsPerOperationC;

const FileUploadModal = ({ onClose, onUpload, documentTitle }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSaveChanges = async () => {
        if (file) {
            // Perform the upload action and call onUpload with the selected file
            await onUpload(file);
        }
        onClose();
    };

    return (
        <div className='fileUploadModal-main'>
            <div className="modal-content">
                <h2 className='text-center'>Subir: {documentTitle}</h2>
                <div style={{textAlign:'center'}}> 
                    <input className='uploadFileInput' type="file" onChange={handleFileChange} />
                </div>
                <button className='btn-square' onClick={handleSaveChanges}>Guardar documento</button>
                <a className='text-center close' onClick={onClose}>Cancelar</a>
            </div>
        </div>
    );
};

const FileAddModal = ({onClose, addNewDocument, handleInputChange, newDocumentData }) => {

    return (
        <div className='fileUploadModal-main'>
            <div className="modal-content">
                <h2 className='text-center'>Agregar nuevo documento</h2>
                <p>Trabajar con tus clientes a través de Camtom Agente es muy sencillo:</p>
                <p>1. Escribe el nombre del documento que tu cliente necesita para la operación.</p>
                <p>2. Elige el tipo de documento al que pertenece.</p>

                <div className='row'>
                    <p className='col-9'>Nombre del documento</p>
                    <p className='col-3'>Tipo</p>
                    <hr/>
                </div>
                        <form className='row'>
                            <input
                                className='col-9 inputNewFile'
                                type="text"
                                name="title"
                                placeholder="Nombre del documento"
                                value={newDocumentData.title}
                                onChange={handleInputChange}
                            />
                            <select
                                className='col-3'
                                name="category"
                                value={newDocumentData.category}
                                onChange={handleInputChange}
                            >
                                <option value="legal">Legal</option>
                                <option value="contable">Contable</option>
                                <option value="operativo">Operativo</option>
                            </select>
                            <button className='btn-square' type='button' onClick={addNewDocument}>Agregar documento a la operación</button>

                            <a className='text-center close' onClick={onClose}>Cancelar</a>
                        </form>
            </div>
        </div>
    );
};
