import { createSlice } from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    logOutUser: (state, action) => {
      state.user = null;
    },
  },
});

const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    agent: null,
  },
  reducers: {
    loginAgent: (state, action) => {
      state.agent = action.payload;
    },
    logOutAgent: (state, action) => {
      state.agent = null;
    },
  },
});

export const { loginUser, logOutUser } = userSlice.actions;
export const { loginAgent, logOutAgent } = agentSlice.actions;

const rootReducer = combineReducers({
    // you can add more reducers here
    user: userSlice.reducer,
    agent: agentSlice.reducer,
})

const persistConfig ={
    key: 'root',
    storage, 
}

export default persistReducer(persistConfig, rootReducer)