import React from "react"
import { useSelector } from 'react-redux';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom"

import Success from "./Success"
import Login from "./Login"
import QuoteForm from "./Companies/QuoteForm"
import DashboardMVP from "./Companies/DashboardMVP"
import AgentDashboard from "./Agents/DashboardAgent";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../redux/store.js'
import FormSinglePageC from "./FormSinglePageC";
import CommonElements from "./style/CommonElements";

import ErrorBoundary from "./ErrorBoundary";

const AppRoutes = () => {
  const userId = useSelector(state => state.user.user?.userId);
  const agentId = useSelector(state => state.agent.agent?.agentId);

  const isPrivateRoute = userId || agentId;

  // Ruta privada
  const PrivateRouteUser = ({ children }) => {
    return userId ? children : <Navigate to="/" replace />;
  };
  const PrivateRouteAgent = ({ children }) => {
    return agentId ? children : <Navigate to="/" replace />;
  };

  return (
    <Router>
      <ErrorBoundary>
        <div className="App">
          <Routes>
            <Route path="/formsinglepage" element={<FormSinglePageC/>}/>
            <Route path="/success/:messageType" element={<Success/>} />
            <Route path="/" element={ userId ? <Navigate to="/dashboard" replace /> : <Login/> }/>
            {/*Ruta al dashboard*/}
            <Route path="/dashboard/*" element={<PrivateRouteUser><DashboardMVP/></PrivateRouteUser>} />
            <Route path="/agent-dashboard/*" element={<PrivateRouteAgent><AgentDashboard/></PrivateRouteAgent>} />
            <Route path="/commoashjdahjsdelements" element={<CommonElements/>}/>
          </Routes>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

const App = () => {
  return(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRoutes />
      </PersistGate>
    </Provider>    
  )
}

export default App
